<template>
  <highcharts class="mt-2" :options="chartOptions" ref="StackColumnChart" :constructor-type="'chart'"></highcharts>
</template>
<script>
import { Chart } from 'highcharts-vue'
import EventBus from '@/commons/event_bus'
import {
  formatDataChartWithStepBarChart,
  getImageTooltipEl,
  getReactionTagTooltipEl,
  getTooltipLineChart,
} from '@/commons/charts'
import { roundDecimal, roundNumberLabelChart } from '@/commons/helpers'
const jQuery = require('jquery')

export default {
  components: {
    highcharts: Chart,
  },
  props: {
    step: {
      type: Number,
      default: 1,
    },
    part: Number,
    reportScreening: [Object, Array],
    answerValueIdSelected: Array,
    selectedAnswerId: [Number, String],
    answerValueSelected: Array,
    seriesData: Array,
    selectedId: String,
    dataReport: [Object, Array],
    project: Object,
    imageURL: String,
    questionIdSelected: [Number, String],
    dataReportSummary: Object,
    reactionButton: [Object, Array],
  },
  watch: {
    step(newVal) {
      let vm = this
      this.chartOptions.xAxis.min = 0
      this.chartOptions.xAxis.labels.formatter = function () {
        if (vm.part > 1) return this.value % 2 ? ((this.value + 1) * vm.step + (vm.part - 1) * 16 * vm.step) / 2 : ''
        return this.value % 2 ? ((this.value + 1) * newVal) / 2 : ''
      }
      this.chartOptions.xAxis.min = -0.5
      this.nextPartCheck()
      this.formatDataChartWithStep()
    },
    maxSecond() {
      this.nextPartCheck()
    },
    part() {
      this.nextPartCheck()
      this.formatDataChartWithStep()
    },
    answerValueIdSelected() {
      if (!this.questionIdSelected) return
      this.formatDataChartWithStep()
    },
    selectedAnswerId() {
      if (!this.questionIdSelected) return
      this.initSeriesData()
    },
    selectedId: function () {
      if (this.questionIdSelected) return
      this.initSeriesData()
    },
    listAttributeId() {
      if (this.questionIdSelected) return
      this.initSeriesData()
    },
  },
  data() {
    let vm = this
    return {
      maxVal: 40,
      minVal: 0,
      maxSecond: 8,
      interactionBtnColor: this.$store.getters.interactioncolors,
      chartOptions: {
        chart: {
          height: '340px',
          type: 'column',
          animation: false,
        },
        legend: {
          enabled: false,
        },
        title: {
          text: '',
        },
        tooltip: {
          useHTML: true,
          formatter: function () {
            return vm.formatTooltip(vm, this)
          },
          borderWidth: 0,
          backgroundColor: 'transparent',
          shadow: false,
          padding: 0,
          followPointer: false,
          shared: false,
          distance: 2,
          className: 'tootlip-line',
        },
        plotOptions: {
          column: {
            pointWidth: window.innerWidth > 1600 ? 10 : 6,
            stacking: 'normal',
            borderColor: '#EBEBEB',
          },
          series: {
            pointPadding: 0.2,
            borderWidth: 0,
            animation: false,
            point: {
              events: {
                click: function (e) {
                  if (vm.questionIdSelected) {
                    EventBus.$emit('selectedAnswerChart', e.target.point.series.userOptions.stack)
                  } else {
                    let idString = e.target.point.series.userOptions.stack
                    let id = idString.split('-')[1]
                    if (id) {
                      EventBus.$emit('selectedChart', id)
                    }
                  }
                },
                mouseOver: function (e) {
                  const currentStack = e.target.series.userOptions.stack
                  vm.chartOptions.series.forEach(function (otherSeries) {
                    if (otherSeries.stack === currentStack) {
                      otherSeries.opacity = 1
                    }
                  })
                },
                mouseOut: function (e) {
                  const currentStack = e.target.series.userOptions.stack
                  const selectedStack = vm.questionIdSelected ? vm.selectedAnswerId : vm.selectedId
                  if (currentStack != selectedStack) {
                    vm.chartOptions.series.forEach(function (otherSeries) {
                      if (otherSeries.stack === currentStack) {
                        otherSeries.opacity = 0.07
                      }
                    })
                  }
                },
              },
            },
          },
        },
        xAxis: {
          min: -0.5,
          max: 15,
          tickWidth: 0,
          lineColor: '#EBEBEB',
          labels: {
            style: {
              color: '#999999',
              fontSize: '8px',
              fontWeight: 500,
              lineHeight: '12px',
            },
            formatter: function () {
              if (vm.part > 1)
                return this.value % 2 ? ((this.value + 1) * vm.step + (vm.part - 1) * 16 * vm.step) / 2 : ''
              return this.value % 2 ? ((this.value + 1) * vm.step) / 2 : ''
            },
          },
        },
        yAxis: {
          title: {
            text: '',
          },
          lineWidth: 1,
          lineColor: '#EBEBEB',
          gridLineWidth: 1,
          gridLineColor: '#EBEBEB',
          ignoreMaxPadding: true,
          labels: {
            style: {
              color: '#999999',
              fontSize: '8px',
              fontWeight: 500,
              lineHeight: '12px',
            },
            formatter: function () {
              return this.value <= 100 && this.value >= -100 ? roundDecimal(Math.abs(this.value), 1) + '%' : ''
            },
          },
        },
        series: [],
      },
    }
  },
  computed: {
    dataSegmentFormatted() {
      if (!this.dataReport) return null
      let listAttributeValues = this.dataReport.attribute_value_ids
      let reportData
      if (listAttributeValues == 'attrValue-all') {
        reportData = this.dataReportSummary?.tally_result
      } else {
        reportData = JSON.parse(JSON.stringify(this.dataReport.data))
      }
      return this.formatDataChart(reportData, listAttributeValues, 'attribute_value_id')
    },

    dataSegmentFormattedToSetYMax() {
      if (!this.dataReport) return null
      let listAttributeValues = this.dataReport.attribute_value_ids
      let reportData
      if (listAttributeValues == 'attrValue-all') {
        reportData = this.dataReportSummary?.tally_result
      } else {
        reportData = JSON.parse(JSON.stringify(this.dataReport.data))
      }
      return this.formatDataChartToSetYMax(reportData, listAttributeValues, 'attribute_value_id')
    },

    dataSegmentAllFormatted() {
      if (!this.dataReportSummary) return null
      let listAttributeValues = 'attrValue-all'
      let reportData = this.dataReportSummary?.tally_result
      return this.formatDataChart(reportData, listAttributeValues, 'attribute_value_id')
    },

    dataSegmentAllFormattedToSetYMax() {
      if (!this.dataReportSummary) return null
      let listAttributeValues = 'attrValue-all'
      let reportData = this.dataReportSummary?.tally_result
      return this.formatDataChartToSetYMax(reportData, listAttributeValues, 'attribute_value_id')
    },

    dataScreeningFormatted() {
      let listAnswerId = this.reportScreening.screening_answer_id
      let reportData
      if (this.reportScreening.data) {
        reportData = JSON.parse(JSON.stringify(this.reportScreening.data))
      }

      return this.formatDataChart(reportData, listAnswerId, 'screening_answer_id')
    },

    dataScreeningAllFormatted() {
      let listAnswerId = 'attrValue-all'

      let reportData
      if (this.dataReportSummary?.tally_result) {
        reportData = JSON.parse(JSON.stringify(this.dataReportSummary?.tally_result))
      }

      return this.formatDataChart(reportData, listAnswerId, 'screening_answer_id')
    },

    dataScreeningFormattedToSetYMax() {
      let listAnswerId = this.reportScreening.screening_answer_id
      let reportData
      if (this.reportScreening.data) {
        reportData = JSON.parse(JSON.stringify(this.reportScreening.data))
      }

      return this.formatDataChartToSetYMax(reportData, listAnswerId, 'screening_answer_id')
    },

    dataScreeningAllFormattedToSetYMax() {
      let listAnswerId = 'attrValue-all'

      let reportData
      if (this.dataReportSummary?.tally_result) {
        reportData = JSON.parse(JSON.stringify(this.dataReportSummary?.tally_result))
      }

      return this.formatDataChartToSetYMax(reportData, listAnswerId, 'screening_answer_id')
    },

    isGoodBad() {
      return (
        this.project && this.project.interaction_btn_1.text == 'Good' && this.project.interaction_btn_2.text == 'Bad'
      )
    },
    listAttributeId() {
      let listAttributeId = []
      if (this.seriesData && this.seriesData.length) {
        this.seriesData.map(attribute => {
          listAttributeId.push(attribute.id)
        })
      }
      return listAttributeId
    },
  },
  methods: {
    initSeriesData() {
      let seriesData = null
      if (!this.dataReport || (this.dataReport && !this.dataReport.data.length)) {
        // reset chart to default
        this.chartOptions.series = [
          {
            data: [0, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            data: [0, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            data: [0, 0, 0, 0, 0, 0, 0, 0],
          },
        ]
        this.chartOptions.yAxis.max = 0.5
        this.chartOptions.yAxis.min = -0.5
        this.chartOptions.yAxis.tickInterval = 0.5
        return
      }
      if (this.reportScreening && this.questionIdSelected) {
        seriesData = this.reportScreening.data
      } else {
        if (this.dataReport.data) seriesData = this.dataReport.data
      }

      if (seriesData && seriesData.length) {
        this.formatDataChartWithStep(seriesData)
      }
    },
    formatDataChartWithStep() {
      if (!this.dataReport) return
      if (!this.questionIdSelected) {
        this.formatDataChartSegmentWithStep()
      } else {
        this.formatDataChartScreeningWithStep()
      }
      this.setYMax()
      this.$nextTick(function () {
        jQuery('#chart-segment .highcharts-point').attr('data-report', 'action')
      })
    },
    formatDataChartScreeningWithStep() {
      if (!this.questionIdSelected) return
      let series = []
      if (
        this.answerValueIdSelected &&
        this.answerValueIdSelected.length &&
        Object.keys(this.dataScreeningFormatted).length
      ) {
        this.answerValueIdSelected.map(val => {
          let data
          if (val == 'all') {
            data = this.dataScreeningAllFormatted[val]
          } else {
            data = this.dataScreeningFormatted[val]
          }

          if (!data) return

          // this function to border below column when behind column is zero
          let dataReformat = this.reformatDataChart(data)
          series.push({
            color: this.interactionBtnColor[0],
            opacity: this.selectedAnswerId == val ? 1 : 0.1,
            data: dataReformat['left_count'],
            stack: val,
            borderRadiusTopRight: 8,
            borderRadiusTopLeft: 8,
            borderWidth: 1,
          })
          series.push({
            color: this.interactionBtnColor[1],
            opacity: this.selectedAnswerId == val ? 1 : 0.07,
            data: dataReformat['right_count'],
            stack: val,
            borderRadiusBottomRight: this.isGoodBad ? 8 : 0,
            borderRadiusBottomLeft: this.isGoodBad ? 8 : 0,
            borderWidth: 1,
          })
          // add border below column when behind column is zero
          series.push({
            color: this.interactionBtnColor[1],
            opacity: this.selectedAnswerId == val ? 1 : 0.07,
            data: dataReformat['reformat'],
            stack: val,
            borderRadiusTopRight: 8,
            borderRadiusTopLeft: 8,
            borderWidth: 1,
          })
        })
      } else {
        for (let i = 0; i < 5; i++) {
          let valArr = []
          for (let i = 1; i <= 16; i++) {
            valArr.push(0)
          }
          series.push({ data: valArr })
        }
      }
      if (this.answerValueIdSelected) {
        this.chartOptions.plotOptions.series.groupPadding = 0.5 - 0.1 * this.answerValueIdSelected.length
      }
      this.chartOptions.series = series
    },
    formatDataChartSegmentWithStep() {
      if (this.questionIdSelected) return
      let series = []
      if (
        this.seriesData &&
        this.seriesData.length &&
        this.dataScreeningFormatted &&
        this.dataSegmentFormatted &&
        Object.keys(this.dataSegmentFormatted).length
      ) {
        this.seriesData.map(val => {
          let attributeValueId = val.id.split('-')[1]
          let data
          if (attributeValueId == 'all') {
            data = this.dataSegmentAllFormatted[attributeValueId]
          } else {
            data = this.dataSegmentFormatted[attributeValueId]
          }

          if (!data) return
          // this function to border below column when behind column is zero
          let dataReformat = this.reformatDataChart(data)
          series.push({
            color: this.interactionBtnColor[0],
            opacity: this.selectedId == val.id ? 1 : 0.1,
            data: dataReformat['left_count'],
            stack: val.id,
            borderRadiusTopRight: 8,
            borderRadiusTopLeft: 8,
            borderWidth: 1,
          })
          series.push({
            color: this.interactionBtnColor[1],
            opacity: this.selectedId == val.id ? 1 : 0.07,
            data: dataReformat['right_count'],
            stack: val.id,
            borderRadiusBottomRight: this.isGoodBad ? 8 : 0,
            borderRadiusBottomLeft: this.isGoodBad ? 8 : 0,
            borderWidth: 1,
          })
          // add border below column when behind column is zero
          series.push({
            color: this.interactionBtnColor[1],
            opacity: this.selectedId == val.id ? 1 : 0.1,
            data: dataReformat['reformat'],
            stack: val.id,
            borderRadiusTopRight: 8,
            borderRadiusTopLeft: 8,
            borderWidth: 1,
          })
        })
      } else {
        for (let i = 0; i < 5; i++) {
          let valArr = []
          for (let i = 1; i <= 16; i++) {
            valArr.push(0)
          }
          series.push({ data: valArr })
        }
      }

      this.chartOptions.plotOptions.series.groupPadding = 0.5 - 0.1 * this.seriesData.length
      this.chartOptions.series = series
    },

    formatDataChart(reportData, listId, keyName) {
      // format data for each id
      let formattedData = {}
      if (!listId) return formattedData
      if (listId == 'attrValue-all') {
        if (reportData) {
          let result = []
          reportData.forEach(data => {
            let attrData
            if (data) {
              attrData = {}
              attrData['second'] = data.second
              attrData['left_count'] = this.reactionButton.btn1.checked ? data.left : 0
              attrData['right_count'] = this.reactionButton.btn2.checked ? data.right : 0
              result.push(attrData)
            } else {
              attrData = {}
              attrData['second'] = data.second
              attrData['left_count'] = 0
              attrData['right_count'] = 0
              result.push(attrData)
            }
          })

          formattedData['all'] = this.formatSeriesDataArray(result)
        }
      } else {
        listId.forEach(id => {
          let result = []
          if (reportData && reportData.length && Array.isArray(reportData)) {
            reportData.forEach(data => {
              let attrData
              attrData = data.values.find(e => id == e[keyName])
              if (attrData) {
                attrData['second'] = data.second
                attrData['left_count'] = this.reactionButton.btn1.checked ? attrData.left_count : 0
                attrData['right_count'] = this.reactionButton.btn2.checked ? attrData.right_count : 0
                result.push(attrData)
              } else {
                attrData = {}
                attrData['second'] = data.second
                attrData['left_count'] = 0
                attrData['right_count'] = 0
                result.push(attrData)
              }
            })

            formattedData[id] = this.formatSeriesDataArray(result)
          }
        })
      }

      return formattedData
    },

    formatDataChartToSetYMax(reportData, listId, keyName) {
      // format data for each id
      let formattedData = {}
      if (!listId) return formattedData
      if (listId == 'attrValue-all') {
        if (reportData) {
          let result = []
          reportData.forEach(data => {
            let attrData
            if (data) {
              attrData = {}
              attrData['second'] = data.second
              attrData['left_count'] = data.left
              attrData['right_count'] = data.right
              result.push(attrData)
            } else {
              attrData = {}
              attrData['second'] = data.second
              attrData['left_count'] = 0
              attrData['right_count'] = 0
              result.push(attrData)
            }
          })

          formattedData['all'] = this.formatSeriesDataArrayToSetYMax(result)
        }
      } else {
        listId.forEach(id => {
          let result = []
          if (reportData && reportData.length && Array.isArray(reportData)) {
            reportData.forEach(data => {
              let attrData
              attrData = data.values.find(e => id == e[keyName])
              if (attrData) {
                attrData['second'] = data.second

                result.push(attrData)
              } else {
                attrData = {}
                attrData['second'] = data.second
                attrData['left_count'] = 0
                attrData['right_count'] = 0
                result.push(attrData)
              }
            })

            formattedData[id] = this.formatSeriesDataArrayToSetYMax(result)
          }
        })
      }

      return formattedData
    },
    formatSeriesDataArray(reportData) {
      let result = {}
      // format to get left_count, right_count
      if (reportData) {
        if (this.reactionButton.btn1.checked) {
          result['left_count'] = reportData.map(e => Math.abs(e.left_count))
        } else if (this.reactionButton.btn2.checked) {
          result['right_count'] = reportData.map(e => Math.abs(e.right_count))
        }
      }

      // format to data in chart
      let minSecond = (this.part - 1) * this.step * 8
      let maxSecond = this.part * this.step * 8
      let leftData = [],
        rightData = []

      let formattedLeftCount = []
      let formattedRightCount = []
      if (this.reactionButton.btn1.checked) {
        formattedLeftCount = formatDataChartWithStepBarChart(result['left_count'], this.step)
      } else if (this.reactionButton.btn2.checked) {
        formattedRightCount = formatDataChartWithStepBarChart(result['right_count'], this.step)
      }

      formattedLeftCount?.map(e => {
        e.y = e.y / this.step
      })

      formattedRightCount?.map(e => {
        e.y = (e.y / this.step) * (this.isGoodBad ? -1 : 1)
      })

      for (let i = minSecond; i <= maxSecond - this.step; i += this.step) {
        let valueLeftCount = formattedLeftCount.find(e => e.x == i)
        if (valueLeftCount) {
          leftData.push(valueLeftCount.y)
        } else {
          leftData.push(0)
        }

        let valueRightCount = formattedRightCount.find(e => e.x == i)
        if (valueRightCount) {
          rightData.push(valueRightCount.y)
        } else {
          rightData.push(0)
        }
      }

      result['left_count'] = leftData
      result['right_count'] = rightData

      // add zero point to make chart
      Object.keys(result).forEach(key => {
        let value = result[key]
        let valueConvert = []
        result[key + '_label'] = value
        for (let i = 0; i < value.length; i++) {
          valueConvert.push(value[i])
          if (i < value.length - 1) {
            valueConvert.push(0)
          }
        }
        result[key] = valueConvert
      })

      result['all_left_count'] = formattedLeftCount?.map(e => e.y)
      result['all_right_count'] = formattedRightCount?.map(e => e.y)

      return result
    },

    formatSeriesDataArrayToSetYMax(reportData) {
      let result = {}
      // format to get left_count, right_count
      if (reportData) {
        result['left_count'] = reportData.map(e => Math.abs(e.left_count))
        result['right_count'] = reportData.map(e => Math.abs(e.right_count))
      }

      // format to data in chart
      let minSecond = (this.part - 1) * this.step * 8
      let maxSecond = this.part * this.step * 8
      let leftData = [],
        rightData = []
      let formattedLeftCount = formatDataChartWithStepBarChart(result['left_count'], this.step)
      let formattedRightCount = formatDataChartWithStepBarChart(result['right_count'], this.step)

      formattedLeftCount.map(e => {
        e.y = e.y / this.step
      })

      formattedRightCount.map(e => {
        e.y = (e.y / this.step) * (this.isGoodBad ? -1 : 1)
      })

      for (let i = minSecond; i <= maxSecond - this.step; i += this.step) {
        let valueLeftCount = formattedLeftCount.find(e => e.x == i)
        if (valueLeftCount) {
          leftData.push(valueLeftCount.y)
        } else {
          leftData.push(0)
        }

        let valueRightCount = formattedRightCount.find(e => e.x == i)
        if (valueRightCount) {
          rightData.push(valueRightCount.y)
        } else {
          rightData.push(0)
        }
      }

      result['left_count'] = leftData
      result['right_count'] = rightData

      // add zero point to make chart
      Object.keys(result).forEach(key => {
        let value = result[key]
        let valueConvert = []
        result[key + '_label'] = value
        for (let i = 0; i < value.length; i++) {
          valueConvert.push(value[i])
          if (i < value.length - 1) {
            valueConvert.push(0)
          }
        }
        result[key] = valueConvert
      })

      result['all_left_count'] = formattedLeftCount?.map(e => e.y)
      result['all_right_count'] = formattedRightCount?.map(e => e.y)

      return result
    },

    reformatDataChart(data) {
      let result = JSON.parse(JSON.stringify(data))
      if (this.isGoodBad) {
        result['reformat'] = []
        return result
      }
      let dataReformat = []
      result['right_count'].forEach((e, index) => {
        if (result['left_count'][index] == 0 && e != 0) {
          dataReformat.push(e)
          result['right_count'][index] = 0
        } else {
          dataReformat.push(0)
        }
      })
      result['reformat'] = dataReformat
      return result
    },
    formatTooltip(vm, tooltip) {
      if (tooltip.key % 2 != 0 || vm.step !== 1) return ''
      let position = tooltip.key / 2

      if (
        (tooltip.series.userOptions.stack != vm.selectedId && !vm.questionIdSelected) ||
        (tooltip.series.userOptions.stack != vm.selectedAnswerId && vm.questionIdSelected)
      )
        return ''

      let tooltipEl
      if (tooltip.series.type == 'column') {
        let data = {
          left: 0,
          right: 0,
        }

        let second = Math.floor(((tooltip.key + 1) * vm.step + (vm.part - 1) * 16 * vm.step) / 2)

        let imgEl = getImageTooltipEl(vm.imageURL + second + '.jpg')

        let leftData, rightData
        if (!vm.questionIdSelected) {
          let attributeValueId = tooltip.series.userOptions.stack.split('-')[1]

          if (attributeValueId == 'all') {
            leftData = roundDecimal(vm.dataSegmentAllFormatted[attributeValueId]['left_count_label'][position], 1)
            rightData = roundDecimal(
              Math.abs(vm.dataSegmentAllFormatted[attributeValueId]['right_count_label'][position]),
              1,
            )
          } else {
            leftData = roundDecimal(vm.dataSegmentFormatted[attributeValueId]['left_count_label'][position], 1)
            rightData = roundDecimal(
              Math.abs(vm.dataSegmentFormatted[attributeValueId]['right_count_label'][position]),
              1,
            )
          }
        } else {
          let answerValueId = tooltip.series.userOptions.stack
          if (answerValueId == 'all') {
            leftData = roundDecimal(vm.dataScreeningAllFormatted[answerValueId]['left_count_label'][position], 1)
            rightData = roundDecimal(
              Math.abs(vm.dataScreeningAllFormatted[answerValueId]['right_count_label'][position]),
              1,
            )
          } else {
            leftData = roundDecimal(vm.dataScreeningFormatted[answerValueId]['left_count_label'][position], 1)
            rightData = roundDecimal(
              Math.abs(vm.dataScreeningFormatted[answerValueId]['right_count_label'][position]),
              1,
            )
          }
        }
        data['left'] = String(leftData) + '% '
        data['right'] = String(rightData) + '% '

        let reactionTagsEl = getReactionTagTooltipEl(vm.$props.project, data)

        if (this.reactionButton.btn1 && !this.reactionButton.btn1.checked) {
          reactionTagsEl.left = ''
        } else if (this.reactionButton.btn2 && !this.reactionButton.btn2.checked) {
          reactionTagsEl.right = ''
        }

        tooltipEl = getTooltipLineChart(imgEl, reactionTagsEl)
        return tooltipEl
      } else {
        return ''
      }
    },
    setYMax() {
      this.maxSecond = 0
      this.maxVal = 0
      this.minVal = 0
      if (this.questionIdSelected && this.answerValueIdSelected.length) {
        this.answerValueIdSelected.map(answer => {
          let listValue = []

          if (answer == 'all') {
            this.dataReportSummary?.tally_result.forEach(data => {
              let attrData
              attrData = data
              if (attrData && (attrData['left'] != 0 || attrData['right'] != 0)) {
                attrData['second'] = data.second
                listValue.push(attrData)
              }
            })
          } else {
            this.reportScreening.data.forEach(data => {
              let attrData
              attrData = data.values.find(e => answer == e['screening_answer_id'])
              if (attrData && (attrData['left_count'] != 0 || attrData['right_count'] != 0)) {
                attrData['second'] = data.second
                listValue.push(attrData)
              }
            })
          }

          if (listValue.length == 0) return
          // change maxSecond
          let maxSecond = Math.max(...listValue.map(e => e['second']))
          if (this.maxSecond < maxSecond) this.maxSecond = maxSecond

          // change maxVal, minVal
          if (this.isGoodBad) {
            let maxVal
            let minVal

            if (answer == 'all') {
              maxVal = Math.max(...this.dataScreeningAllFormatted[answer]['all_left_count'])
              minVal = Math.min(...this.dataScreeningAllFormatted[answer]['all_right_count'])
            } else {
              maxVal = Math.max(...this.dataScreeningFormatted[answer]['all_left_count'])
              minVal = Math.min(...this.dataScreeningFormatted[answer]['all_right_count'])
            }

            if (this.maxVal < maxVal && typeof maxVal == 'number') this.maxVal = maxVal
            if (this.minVal > minVal && typeof minVal == 'number') this.minVal = minVal
          } else {
            let maxVal
            if (answer == 'all') {
              maxVal = Math.max(
                ...this.dataScreeningAllFormattedToSetYMax[answer]['all_left_count'].map(
                  (e, index) => e + this.dataScreeningAllFormattedToSetYMax[answer]['all_right_count'][index],
                ),
              )
            } else {
              maxVal = Math.max(
                ...this.dataScreeningFormattedToSetYMax[answer]['all_left_count'].map(
                  (e, index) => e + this.dataScreeningFormattedToSetYMax[answer]['all_right_count'][index],
                ),
              )
            }

            if (this.maxVal < maxVal && typeof maxVal == 'number') this.maxVal = maxVal
            this.minVal = 0
          }
        })
      } else if (this.seriesData && this.seriesData.length) {
        this.seriesData.map(attribute => {
          let listValue = []
          const attributeValueId = attribute.id.split('-')[1]
          let attrData
          //1429

          if (attributeValueId == 'all') {
            this.dataReportSummary?.tally_result.forEach(data => {
              attrData = data
              if (attrData && (attrData['left'] != 0 || attrData['right'] != 0)) {
                attrData['second'] = data.second
                listValue.push(attrData)
              }
            })
          } else {
            if (this.dataReport && Array.isArray(this.dataReport.data)) {
              this.dataReport.data.forEach(data => {
                let attrData
                attrData = data.values.find(e => attributeValueId == e['attribute_value_id'])
                if (attrData && (attrData['left_count'] != 0 || attrData['right_count'] != 0)) {
                  attrData['second'] = data.second
                  listValue.push(attrData)
                }
              })
            }
          }

          if (listValue.length == 0) return
          // change maxSecond
          let maxSecond = Math.max(...listValue.map(e => e['second']))
          if (this.maxSecond < maxSecond) this.maxSecond = maxSecond
          // change maxVal, minVal

          if (this.isGoodBad) {
            let maxVal
            let minVal
            if (attributeValueId == 'all') {
              maxVal = Math.max(...this.dataSegmentAllFormatted[attributeValueId]['all_left_count'])

              minVal = Math.min(...this.dataSegmentAllFormatted[attributeValueId]['all_right_count'])
            } else {
              maxVal = Math.max(...this.dataSegmentFormatted[attributeValueId]['all_left_count'])
              minVal = Math.min(...this.dataSegmentFormatted[attributeValueId]['all_right_count'])
            }

            if (this.maxVal < maxVal && typeof maxVal == 'number') this.maxVal = maxVal
            if (this.minVal > minVal && typeof minVal == 'number') this.minVal = minVal
          } else {
            let maxVal
            if (attributeValueId == 'all') {
              if (this.reactionButton.btn1 && this.reactionButton.btn1.checked) {
                maxVal = Math.max(...this.dataSegmentAllFormatted[attributeValueId]['all_left_count'])
              } else if (this.reactionButton.btn2 && this.reactionButton.btn2.checked) {
                maxVal = Math.max(...this.dataSegmentAllFormatted[attributeValueId]['all_right_count'])
              } else {
                maxVal = Math.max(
                  ...this.dataSegmentAllFormattedToSetYMax[attributeValueId]['all_left_count']?.map(
                    (e, index) => e + this.dataSegmentAllFormattedToSetYMax[attributeValueId]['all_right_count'][index],
                  ),
                )
              }
            } else {
              if (this.reactionButton.btn1 && this.reactionButton.btn1.checked) {
                maxVal = Math.max(...this.dataSegmentFormatted[attributeValueId]['all_left_count'])
              } else if (this.reactionButton.btn2 && this.reactionButton.btn2.checked) {
                maxVal = Math.max(...this.dataSegmentFormatted[attributeValueId]['all_right_count'])
              } else {
                maxVal = Math.max(
                  ...this.dataSegmentFormattedToSetYMax[attributeValueId]['all_left_count']?.map(
                    (e, index) => e + this.dataSegmentFormattedToSetYMax[attributeValueId]['all_right_count'][index],
                  ),
                )
              }
            }

            if (this.maxVal < maxVal && typeof maxVal == 'number') this.maxVal = maxVal
            this.minVal = 0
          }
        })
      }
      const { max: min, interval: interval_min } = roundNumberLabelChart(this.minVal)
      const { max, interval: interval_max } = roundNumberLabelChart(this.maxVal)
      this.chartOptions.yAxis.max = max <= 100 ? max : 100

      if (this.isGoodBad) {
        if (this.minVal != 0) {
          this.chartOptions.yAxis.min = min >= -100 ? min : -100
        } else {
          this.chartOptions.yAxis.min = -0.5
        }
      } else {
        this.chartOptions.yAxis.min = 0
      }

      this.chartOptions.yAxis.tickInterval = Math.max(interval_min, interval_max)
      this.chartOptions.xAxis.tickInterval = 1
    },
    nextPartCheck() {
      if (this.maxSecond >= this.part * this.step * 8) EventBus.$emit('nextPartActive', true)
      else EventBus.$emit('nextPartActive', false)
    },
  },
  mounted() {
    this.initSeriesData()
  },
  created() {},
}
</script>
<style lang="scss" scoped>
::v-deep {
  .highcharts-credits {
    display: none;
  }

  .highcharts-series {
    opacity: 1;
  }

  .highcharts-container {
    margin-top: 10px;
  }
}
</style>
