<template>
  <div class="mt-5 pb-4">
    <div class="title" id="top-comment-detail">コメントディスカバリーAI</div>
    <div class="d-flex justify-content-between mt-5 iframe-height">
      <interaction-comment-chart
        class="iframe-width"
        :videoDetail="videoDetail"
        :videoImage="videoImage"
        :reportVideo="reportVideo"
        :comments="comments"
        :scenes="scenes"
        @secondInteractionTotalData="getInteractionTotal"
        :all-comments="allComments"
      >
      </interaction-comment-chart>
      <div class="comment-list-width">
        <comment-detail
          :comments="comments"
        >
        </comment-detail>
        <interaction-bar
          :videoDetail="videoDetail"
          :reportVideo="reportVideo"
          :comments="comments"
          :secondInteractionTotalData="secondInteractionTotalData"
        >
        </interaction-bar>
      </div>
    </div>
    <div class="d-flex justify-content-between mt-5 iframe-height comment-content">
      <div class="d-flex flex-column iframe-width">
        <div class="top-comment-detail">
          <div class="word-cloud-content">
            <div class="word-cloud-iframe" v-html="comments.word_cloud_html"></div>
          </div>
          <comment-filter
              :filterData="filterData"
              :videoDetail="videoDetail"
          >
          </comment-filter>
        </div>
        <comment-summary
          v-if="isCommentCsv == 3"
          :commentSummary="commentSummary"
        >
        </comment-summary>
      </div>
      <comment-list
        class="comment-list-width"
        :videoDetail="videoDetail"
        :comments="comments"
        :commentNotFoundMsg="commentNotFoundMsg"
        :keyword="keyword"
        :attributeIds="attributeIds"
        :attributeIdSelected="attributeIdSelected"
        :attributeValueCommentCount="attributeValueCommentCount"
        :isCsvCommentUploading="isCsvCommentUploading"
        :isCommentCsv="isCommentCsv"
      >
      </comment-list>
      <div class="loading-wrapper" v-if="$store.getters.isLoadingApiVideoComment">
        <div class="spinner"></div>
      </div>
    </div>
  </div>
</template>
<script>
import InteractionCommentChart from "@/components/TopDetail/InteractionCommentChart.vue";
import CommentDetail from "@/components/TopDetail/CommentDetail.vue";
import CommentList from "@/components/TopDetail/CommentList.vue";
import CommentSummary from  "@/components/TopDetail/CommentSummary.vue";
import InteractionBar from "@/components/TopDetail/InteractionBar.vue";
import CommentFilter from "@/components/TopDetail/CommentFilter.vue";

export default {
  props: [
    'videoDetail',
    'videoImage',
    'reportVideo',
    'comments',
    'keyword',
    'attributeIds',
    'attributeIdSelected',
    'scenes',
    'commentNotFoundMsg',
    'attributeValueCommentCount',
    'isCsvCommentUploading',
    'isCommentCsv',
    'commentSummary',
    'filterData',
    'allComments',
  ],
  components: {
    CommentFilter,
    CommentList,
    CommentDetail,
    InteractionBar,
    CommentSummary,
    InteractionCommentChart
  },
  data() {
    return {
      secondInteractionTotalData: {},
    }
  },
  methods: {
    getInteractionTotal(val){
      this.secondInteractionTotalData = val
    }
  }
}
</script>
<style lang="scss" scoped>
.over-hidden {
  overflow: hidden;
}
.iframe-height {
  height: auto;
}

.iframe-width {
  width: calc(66% - 10px);
  .word-cloud-content {
    border: 1px solid #EBEBEB;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    height: 467px !important;
  }
  .word-cloud-iframe {
    width: calc(100% + 2px);
    height: 469px;
    margin-left: -1px;
    margin-top: -1px;
    overflow: hidden;
  }
}

.comment-list-width {
  width: calc(34% - 10px);
}

.comment-content {
  position: relative;
  .loading-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000a6;
    border-radius: 8px;
  }

  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #49AB94;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  .iframe-width {
    justify-content: space-between;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

</style>