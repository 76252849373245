<template>
  <highcharts
    class="mt-2 intention-option"
    :options="chartOptions"
    ref="BasicColumnChart"
    :constructor-type="'chart'"
  ></highcharts>
</template>
<script>
import { Chart } from 'highcharts-vue'

const jQuery = require('jquery')
import EventBus from '@/commons/event_bus'
import { roundDecimal, roundNumberLabelChart } from '@/commons/helpers'
import {
  formatDataChartWithStepBarChart,
  getImageTooltipEl,
  getReactionTagTooltipEl,
  getTooltipLineChart,
} from '@/commons/charts'

export default {
  components: {
    highcharts: Chart,
  },
  props: {
    step: {
      type: Number,
      default: 1,
    },
    part: Number,
    seriesData: Array,
    selectedId: Number,
    project: Object,
    imageURL: String,
    intentionLevelSelected: Array,
    areaRangeLineSeriesData: Array,
    dataReportSummary: Object,
  },
  watch: {
    step(newVal) {
      let vm = this
      this.chartOptions.xAxis.min = 0
      this.chartOptions.xAxis.labels.formatter = function () {
        if (vm.part > 1) return this.value % 2 ? ((this.value + 1) * vm.step + (vm.part - 1) * 16 * vm.step) / 2 : ''
        return this.value % 2 ? ((this.value + 1) * newVal) / 2 : ''
      }
      this.chartOptions.xAxis.min = -0.5
      this.nextPartCheck()
      this.formatDataChartWithStep()
    },
    maxSecond() {
      this.nextPartCheck()
    },
    part() {
      this.nextPartCheck()
      this.formatDataChartWithStep()
    },
    selectedId: function () {
      this.initSeriesData()
    },
    seriesData: function () {
      this.initSeriesData()
    },
  },
  data() {
    let vm = this
    return {
      maxSecond: 8,
      chartOptions: {
        chart: {
          height: '340px',
          type: 'column',
          animation: false,
        },
        legend: {
          enabled: false,
        },
        title: {
          text: '',
        },
        tooltip: {
          useHTML: true,
          crossHair: [false, true],
          formatter: function () {
            return vm.formatTooltip(vm, this)
          },
          borderWidth: 0,
          backgroundColor: 'transparent',
          shadow: false,
          padding: 0,
          followPointer: false,
          shared: false,
          distance: 2,
          className: 'tootlip-line',
        },
        plotOptions: {
          column: {
            pointWidth: window.innerWidth > 1600 ? 10 : 6,
          },
          series: {
            borderRadiusTopRight: 8,
            borderRadiusTopLeft: 8,
            pointPadding: 0.2,
            borderWidth: 0,
            animation: false,
            point: {
              events: {
                click: function (e) {
                  if (vm.questionIdSelected) {
                    EventBus.$emit('selectedAnswerChart', e.target.point.series.userOptions.id)
                  } else {
                    let idString = e.target.point.series.userOptions.id
                    if (idString || idString === 0) {
                      EventBus.$emit('selectedChart', idString)
                    }
                  }
                },
                mouseOver: function () {
                  vm.$nextTick(() => {
                    let elementHover = jQuery('.highcharts-point-hover')
                    elementHover.css('fill', '#49AB94')
                    elementHover.siblings().css('fill', '#49AB94')
                    this.series.data.forEach(function (point) {
                      point.update({
                        opacity: 1,
                      })
                    })
                  })
                },
                mouseOut: function () {
                  vm.$nextTick(() => {
                    jQuery('.highcharts-point').removeAttr('style')
                    this.series.data.forEach(function (point) {
                      point.update({
                        opacity: 1,
                      })
                    })
                  })
                },
              },
            },
          },
        },
        xAxis: {
          min: -0.5,
          max: 15,
          tickWidth: 0,
          lineColor: '#FFFFFF',
          gridLineColor: '#EBEBEB',
          gridLineWidth: 1,
          labels: {
            style: {
              color: '#999999',
              fontSize: '8px',
              fontWeight: 500,
              lineHeight: '12px',
            },
            formatter: function () {
              if (vm.part > 1)
                return this.value % 2 ? ((this.value + 1) * vm.step + (vm.part - 1) * 16 * vm.step) / 2 : ''
              return this.value % 2 ? ((this.value + 1) * vm.step) / 2 : ''
            },
          },
        },
        yAxis: {
          title: {
            text: '',
          },
          lineWidth: 1,
          lineColor: '#EBEBEB',
          gridLineWidth: 0,
          ignoreMaxPadding: true,
          min: 0,
          labels: {
            style: {
              color: '#999999',
              fontSize: '8px',
              fontWeight: 500,
              lineHeight: '12px',
            },
            formatter: function () {
              return this.value <= 100 ? roundDecimal(this.value, 1) + '%' : ''
            },
          },
        },
        series: [],
      },
    }
  },
  computed: {
    dataScreeningFormatted() {
      return this.formatDataChart(this.areaRangeLineSeriesData, this.intentionLevelSelected)
    },
  },
  methods: {
    initJQuery() {
      let grids = jQuery('.intention-option').find('.highcharts-xaxis-grid').last().children()
      for (let i = 0; i < 16; i++) {
        if (i % 2) grids.eq(i).css('display', 'none')
      }

      jQuery('#chart-report-intention .highcharts-point').attr('data-report', 'action')
    },
    initSeriesData() {
      this.formatDataChartWithStep()
    },
    formatDataChartWithStep() {
      this.formatDataChartScreeningWithStep()
      this.setYMax()
    },
    formatDataChartScreeningWithStep() {
      let series = []
      if (
        this.intentionLevelSelected &&
        this.intentionLevelSelected.length &&
        Object.keys(this.dataScreeningFormatted).length
      ) {
        this.intentionLevelSelected.map(val => {
          let data = this.dataScreeningFormatted[val]
          if (!data) return
          series.push({
            id: val,
            color: this.selectedId == val ? '#49AB94' : '#EDF7F4',
            data: data['total'],
          })
        })
      } else {
        for (let i = 0; i < 5; i++) {
          let valArr = []
          for (let i = 1; i <= 16; i++) {
            valArr.push(0)
          }
          series.push({ data: valArr })
        }
      }

      this.chartOptions.plotOptions.series.groupPadding = 0.5 - 0.1 * series.length
      this.chartOptions.series = series
      this.$nextTick(() => {
        this.initJQuery()
      })
    },
    formatDataChart(reportData, listId) {
      // format data for each id
      let formattedData = {}
      listId.forEach(id => {
        let result = []
        if (reportData.length) {
          reportData.forEach(report => {
            if (report.id == id) {
              report.data.forEach(data => {
                let attrData = {}
                attrData['second'] = data[0]
                attrData['left_count'] = data[1]
                attrData['right_count'] = data[2]
                result.push(attrData)
              })
            }
          })

          formattedData[id] = this.formatSeriesDataArray(result)
        }
      })

      return formattedData
    },
    formatSeriesDataArray(reportData) {
      let result = {}
      // format to get left_count, right_count, total
      if (reportData) {
        result['left_count'] = reportData.map(e => Math.abs(e.left_count))
        result['right_count'] = reportData.map(e => Math.abs(e.right_count))
        result['total'] = reportData.map(e => Math.abs(e.left_count) + Math.abs(e.right_count))
      }

      // format to data in chart
      let minSecond = (this.part - 1) * this.step * 8
      let maxSecond = this.part * this.step * 8
      let leftData = [],
        rightData = [],
        totalData = []
      let formattedLeftCount = formatDataChartWithStepBarChart(result['left_count'], this.step)
      let formattedRightCount = formatDataChartWithStepBarChart(result['right_count'], this.step)
      let formattedTotal = formatDataChartWithStepBarChart(result['total'], this.step)
      for (let i = minSecond; i <= maxSecond - this.step; i += this.step) {
        let valueLeftCount = formattedLeftCount.find(e => e.x == i)
        if (valueLeftCount) {
          leftData.push(valueLeftCount.y / this.step)
        } else {
          leftData.push(0)
        }

        let valueRightCount = formattedRightCount.find(e => e.x == i)
        if (valueRightCount) {
          rightData.push(valueRightCount.y / this.step)
        } else {
          rightData.push(0)
        }

        let valueTotal = formattedTotal.find(e => e.x == i)
        if (valueTotal) {
          totalData.push(valueTotal.y / this.step)
        } else {
          totalData.push(0)
        }
      }

      result['left_count'] = leftData
      result['right_count'] = rightData
      result['total'] = totalData
      result['all_total'] = formattedTotal.map(e => e.y / this.step)

      // add zero point to make chart
      let valueTotal = result['total']
      let valueConvert = []
      for (let i = 0; i < valueTotal.length; i++) {
        valueConvert.push(valueTotal[i])
        if (i < valueTotal.length - 1) {
          valueConvert.push(0)
        }
      }
      result['total'] = valueConvert

      return result
    },
    formatTooltip(vm, tooltip) {
      if (tooltip.key % 2 != 0 || vm.step !== 1) return ''
      let position = tooltip.key / 2
      if (tooltip.series.userOptions.id != vm.selectedId) return ''

      let tooltipEl
      if (tooltip.series.type == 'column') {
        let data = {
          left: 0,
          right: 0,
        }

        let second = Math.floor(((tooltip.key + 1) * vm.step + (vm.part - 1) * 16 * vm.step) / 2)
        let imgEl = getImageTooltipEl(vm.imageURL + second + '.jpg')

        let leftData, rightData
        let answerValueId = tooltip.series.userOptions.id
        leftData = roundDecimal(vm.dataScreeningFormatted[answerValueId]['left_count'][position], 1)
        rightData = roundDecimal(vm.dataScreeningFormatted[answerValueId]['right_count'][position], 1)

        data['left'] = String(leftData) + '% '
        data['right'] = String(rightData) + '% '

        let reactionTagsEl = getReactionTagTooltipEl(vm.$props.project, data)
        tooltipEl = getTooltipLineChart(imgEl, reactionTagsEl)
        return tooltipEl
      } else {
        return ''
      }
    },
    setYMax() {
      this.maxSecond = 0
      let maxValue = 0
      if (this.seriesData && this.seriesData.length) {
        this.seriesData.map(attribute => {
          // get max value
          let max = Math.max(...this.dataScreeningFormatted[attribute.id]['all_total'])
          if (maxValue < max) maxValue = max
          this.maxSecond = attribute.data.length
        })
      }

      // set maxY for chart
      if (maxValue > 0) {
        const { max, interval } = roundNumberLabelChart(maxValue)
        this.chartOptions.yAxis.max = max <= 100 ? max : 100
        this.chartOptions.yAxis.tickInterval = interval
      }
      this.chartOptions.xAxis.tickInterval = 1
    },
    nextPartCheck() {
      if (this.maxSecond >= this.part * this.step * 8) EventBus.$emit('nextPartActive', true)
      else EventBus.$emit('nextPartActive', false)
    },
  },
  mounted() {
    this.initSeriesData()
  },
  created() {},
}
</script>
<style lang="scss" scoped>
::v-deep {
  .highcharts-credits {
    display: none;
  }

  .highcharts-series {
    opacity: 1;
  }

  .highcharts-container {
    margin-top: 10px;
  }
}
</style>
