<template>
  <div>
    <highcharts
      v-show="chartMode == listChartMode.line.value"
      :id="isArea ? 'chart-container-2' : 'chart-container-1'"
      class="mt-2"
      :options="chartOptions"
      ref="lineChartSummary"
      :constructor-type="'chart'"
    ></highcharts>
    <column-chart
      v-show="chartMode == listChartMode.column.value"
      :reportScreening="reportScreening"
      :answerValueIdSelected="answerValueIdSelected"
      :selectedAnswerId="selectedAnswerId"
      :step="step"
      :part="part"
      :answerValueSelected="answerValueSelected"
      :seriesData="seriesData"
      :allSeriesData="allSeriesData"
      :selectedId="selectedId"
      :dataReport="dataReport"
      :dataReportSummary="dataReportSummary"
      :questionIdSelected="questionIdSelected"
      :project="project"
      :imageURL="imageURL"
    ></column-chart>
  </div>
</template>
<script>
import { Chart } from 'highcharts-vue'
import ColumnChart from '@/components/Charts/Segment/Column.vue'
import {
  getXColumnsByStep,
  formatDataChartWithStep,
  lineChartOption,
  getReactionTagTooltipEl,
  getImageTooltipEl,
  getTooltipLineChart,
} from '@/commons/charts'

const jQuery = require('jquery')
import EventBus from '@/commons/event_bus'
import { colorInteraction, listChartMode } from '@/commons/constants'
import { roundDecimal, roundNumberLabelChart } from '@/commons/helpers'

export default {
  props: {
    dataReportSummary: Object,
    changeStepChart: String,
    seriesData: Array,
    allSeriesData: Array,
    selectedId: String,
    dataReport: [Object, Array],
    imageURL: String,
    step: Number,
    part: Number,
    column: Number,
    maxColumn: Number,
    lineChart: String,
    interactionType: Number,
    nameChart: String,
    project: Object,
    isArea: Boolean,
    reportScreening: [Object, Array],
    answerValueIdSelected: Array,
    selectedAnswerId: [Number, String],
    questionIdSelected: [Number, String],
    answerValueSelected: Array,
    dynamicButton: { type: Boolean, default: false },
    isShared: { type: Boolean, default: false },
    numberElmTooltip: { type: Number, default: 2 }, // Number elements of tooltip
    chartMode: Number,
  },
  components: {
    highcharts: Chart,
    ColumnChart,
  },
  watch: {
    step: function (newValue) {
      this.chartOptions.xAxis.tickInterval = newValue
      this.chartOptions.xAxis.grid = {
        columns: getXColumnsByStep(newValue, 0),
      }
      this.prevSelected = this.currentSelected
      this.currentSelected = 0
      this.chartOptions.xAxis.min = 0
      this.chartOptions.xAxis.max = newValue * this.maxColumn
      this.clearTooltip()
      this.resetToolTip()
      this.initSeriesData()
    },
    part: function (newValue, oldValue) {
      this.prevPart = oldValue
      this.prevSelected = this.currentSelected
      this.currentSelected = (newValue - 1) * this.step * this.column
      this.chartOptions.xAxis.min = (newValue - 1) * this.step * this.column
      this.chartOptions.xAxis.tickInterval = this.step
      this.chartOptions.xAxis.max = (newValue - 1) * this.step * this.column + this.step * this.maxColumn
      this.chartOptions.xAxis.grid = {
        columns: getXColumnsByStep(this.step, (newValue - 1) * this.step * this.column),
      }
      this.clearTooltip()
      this.resetToolTip()
      this.initSeriesData()
    },
    seriesData: {
      handler: function () {
        this.initSeriesData()
        if (!this.dataReport) return
        while (this.chart.series.length > 0) this.chart.series[0].remove(false)
        this.chartOptions.series.forEach(serie => {
          this.chart.addSeries(serie, false)
        })
        this.$nextTick(() => {
          this.chart.redraw()
          if ((this.selectedId && !this.questionIdSelected) || (this.selectedAnswerId && this.questionIdSelected)) {
            this.loaded(this.chart)
          }
        })
      },
      deep: true,
    },
    selectedId: function () {
      if (this.questionIdSelected) return
      this.updateSelectedId()
    },
    selectedAnswerId: function () {
      if (!this.questionIdSelected) return
      this.updateSelectedId()
    },
    chartMode: function () {
      this.updateSelectedId()
    },
    imageURL: function () {
      this.clearTooltip()
      this.loaded(this.chart)
    },
  },
  computed: {
    reportVideo() {
      return this.seriesData.filter(e =>
        function () {
          if (this.questionIdSelected) {
            return e.id == this.selectedAnswerId
          } else {
            return e.mainId ? e.mainId == this.selectedId : e.id == this.selectedId
          }
        }.bind(this)(),
      )
    },

    arrIdSelected() {
      return this.reportVideo.map(item => item.id)
    },
  },
  data() {
    let prevSelected = 0
    let prevStep = 1
    let tooltipEl, tooltipEl2
    let line, image, image2, clickX, clickY
    let currentWidth
    let chartOptions = JSON.parse(JSON.stringify(lineChartOption))
    chartOptions.yAxis = {
      ...chartOptions.yAxis,
      startOnTick: false,
      endOnTick: false,
      max: 110,
      min: 0,
      labels: {
        ...chartOptions.yAxis.labels,
        formatter: function () {
          return this.value <= 100 ? roundDecimal(this.value, 1) + '%' : ''
        },
      },
    }
    let vm = this

    return {
      chart: null,
      currentSelected: 0,
      prevSelected,
      prevStep,
      tooltipEl,
      tooltipEl2,
      line,
      currentWidth,
      image,
      image2,
      clickX,
      clickY,
      prevPart: 1,
      chartOptions: {
        chart: {
          animation: false,
          height: '340px',
          events: {
            load: function (e) {
              vm.load(e)
            },
            redraw: function (e) {
              vm.redraw(e)
            },
          },
        },
        ...chartOptions,
      },
      positionTooltip: 0,
      listChartMode,
    }
  },
  methods: {
    updateSelectedId() {
      if (this.chartMode != listChartMode.line.value) return
      if (this.chart.xAxis[0].options.plotLines && this.chart.xAxis[0].options.plotLines.length) {
        this.chart.xAxis[0].removePlotLine('plotLineTooltip')
        this.chart.xAxis[0].update()
      }
      this.clearTooltip()
      this.resetToolTip()
      this.initSeriesData()
      this.$nextTick(() => {
        if (!this.dataReport) return
        this.chart.series.forEach(serie => {
          serie.update(
            this.chartOptions.series.find(e => e.name == serie.name),
            false,
          )
        })
        this.chart.redraw()
      })

      this.loaded(this.chart)
    },
    getXMax(chart) {
      let arrChartsSelected = []
      this.arrIdSelected.forEach(idSelected => {
        arrChartsSelected = [...arrChartsSelected, ...chart.get(idSelected).xData]
      })
      return Math.max(...arrChartsSelected)
    },
    selectedChart(name) {
      EventBus.$emit('selectedChart', name)
    },
    hoverLine(e) {
      if (e.target.series) {
        let id = e.target.series.id
        this.chart.series.forEach(serie => {
          if (serie.id == id) {
            serie.setState('hover')
          } else {
            serie.setState('')
          }
        })
        if (id) {
          this.$emit('addClassActive', id)
        }
      }
    },
    mouseOut(e) {
      if (e.target.series) {
        let name = e.target.series.name
        if (name) {
          //remove class active
          this.$emit('removeClassActive', name)
        }
      }
    },
    initSeriesData() {
      let series = []
      let vm = this
      if (!this.dataReport || (this.dataReport && !this.dataReport.data.length)) {
        // reset chart to default
        this.chartOptions.series = {
          data: [],
        }
        this.chartOptions.yAxis.max = 0.5
        this.chartOptions.yAxis.tickInterval = 0.5
        return
      }
      this.seriesData.forEach(seriesData => {
        let { data, id, mainId, colorLine } = seriesData
        let active
        // for segment
        if (!this.questionIdSelected) {
          active = mainId ? this.selectedId == mainId : this.selectedId === id
        } else {
          active = this.selectedAnswerId == id
        }
        let color
        if (this.isArea || this.isShared) {
          color = colorInteraction[colorLine]
        } else {
          color = { color: '#49AB94' }
        }
        let serie = {
          className: 'segment-line',
          mainId: mainId,
          id,
          ...color,
          name: seriesData.id,
          data: formatDataChartWithStep(data),
          zoneAxis: 'x',
          zones: [
            {
              value: this.step * this.part * this.column,
            },
            {
              dashStyle: 'ShortDash',
            },
          ],
          opacity: active ? 1 : 0.25,
          findNearestPointBy: 'xy',
          states: {
            hover: {
              opacity: 1,
              lineWidth: 2,
            },
            ...(active
              ? {
                  inactive: {
                    opacity: 1,
                  },
                }
              : {}),
          },
          point: {
            events: {
              mouseOver: this.hoverLine,
              mouseOut: this.mouseOut,
            },
          },
          events: {
            click: function () {
              if (!vm.questionIdSelected) {
                let ids
                ids = this.userOptions?.mainId ? this.userOptions?.mainId.split('-')[1] : this.name.split('-')[1]
                let selected = ids
                vm.selectedChart(selected)
              } else {
                EventBus.$emit('selectedAnswerChart', this.name)
              }
            },
          },
          marker: {
            fillColor: '#FFFFFF',
            lineWidth: 2,
            lineColor: null,
            symbol: 'circle',
            radius: 5,
            enabled: false,
          },
          zIndex: 1,
        }
        if (active) {
          let current = serie.data.find(e => e.x == this.currentSelected)
          if (current) {
            current.marker = { enabled: true }
          }
        } else if (active) {
          serie.zIndex = 2
        } else {
          serie.data.map(e => {
            e.marker = { enabled: false }
            return e
          })
        }
        series.push(serie)
      })

      let maxY = this.getMaxY(series)
      const { max, interval } = roundNumberLabelChart(maxY)
      this.chartOptions.yAxis.max = max <= 100 ? max : 100
      this.chartOptions.yAxis.tickInterval = interval

      this.chartOptions.series = series

      this.$nextTick(function () {
        jQuery('#chart-segment .segment-line').attr('data-report', 'action')
      })
    },
    resetToolTip() {
      this.tooltipEl = null
      this.image = null
      this.image2 = null
    },
    clearTooltip() {
      if (this.tooltipEl) {
        this.tooltipEl.destroy()
        this.tooltipEl = null
      }
      if (this.image) {
        this.image.destroy()
        this.image = null
      }
      if (this.image2) {
        this.image2.destroy()
        this.image2 = null
      }
    },
    load(e) {
      let chart = e.target
      this.chart = chart
      this.loaded(chart)
    },
    loaded(chart) {
      if (this.reportVideo) {
        if (this.getXMax(chart) < this.currentSelected) {
          this.currentSelected = this.step * this.column * (this.part - 1)
        }
        chart.xAxis[0].min = chart.xAxis[0].min < 0 ? 0 : chart.xAxis[0].min
        let numberColumn = (this.currentSelected - chart.xAxis[0].min) / this.step
        let positionX = (numberColumn * chart.plotWidth) / this.maxColumn + 5
        let yMax = chart.yAxis[0].max
        let currentX = this.currentSelected
        if (chart.get(this.arrIdSelected[0])?.yData?.length > currentX) {
          let numberMarker = 1
          if (this.isShared) {
            numberMarker = this.numberElmTooltip
          }
          let positionYMax = Number.MAX_VALUE
          for (let i = 1; i <= numberMarker; i++) {
            chart.get(this.arrIdSelected[i - 1]).data.map(e => {
              if (e.x != this.currentSelected) {
                e.marker = {}
              } else {
                e.marker = { enabled: true }
              }
              return e
            })
            let currentY = chart.get(this.arrIdSelected[i - 1]).yData[currentX]
            let positionY = chart.plotHeight - (currentY / yMax) * chart.plotHeight - 69
            if (positionY < 50) {
              positionY = chart.plotHeight - (currentY / yMax) * chart.plotHeight + 30
            }

            // if currentY and yMax = 100, increase positionY
            // if currentY and yMax = 100, increase positionY
            if (currentY >= 100) {
              positionY = currentY
            }
            if (currentY == 100 && yMax == 100) {
              positionY = positionY + 20
            }

            if (positionY < positionYMax) positionYMax = positionY
          }

          if (chart.xAxis[0].options.plotLines && chart.xAxis[0].options.plotLines.length) {
            chart.xAxis[0].options.plotLines[0].value = this.currentSelected
          } else {
            chart.xAxis[0].addPlotLine({
              className: 'line-show-tooltip',
              color: '#49AB94',
              width: 1,
              value: this.currentSelected,
              id: 'plotLineTooltip',
              zIndex: 2,
            })
          }

          chart.xAxis[0].update()
          this.$nextTick(() => {
            this.clearTooltip()
            this.drawTooltipSVG(chart, positionX, positionYMax)
            let gridBox = chart.xAxis[0].gridGroup.getBBox()
            let widthColumn = gridBox.width / this.column
            let currentSeries
            if (!this.questionIdSelected) {
              currentSeries = this.chartOptions.series.filter(e =>
                e.mainId ? e.mainId == this.selectedId : e.name == this.selectedId,
              )
            } else {
              currentSeries = this.chartOptions.series.filter(e => e.name == this.selectedAnswerId)
            }

            let currentSeriesData = []
            currentSeries.forEach(serie => {
              currentSeriesData = [...currentSeriesData, ...serie.data]
            })
            let maxChart = Math.max(...currentSeriesData.map(e => e.x))
            let currentVideoMaxX = maxChart < chart.xAxis[0].max ? maxChart : chart.xAxis[0].max - this.step / 2
            if (numberColumn == Math.floor((currentVideoMaxX - (this.part - 1) * this.column) / this.step)) {
              this.image = null
            } else {
              this.image = chart.renderer
                .image('/images/svg/icon_next_line.svg', gridBox.x + numberColumn * widthColumn + 1, 10, 28, 16)
                .attr({
                  zIndex: 100,
                  cursor: 'pointer',
                  'data-report': 'action',
                })
                .translate(0, 0)
                .addClass('image-plot-line image-plot-line-next')
                .on('mousedown', this.start)
                .add()
              this.image.on('mousedown', this.start)
            }
            if (numberColumn > 0) {
              this.image2 = chart.renderer
                .image('/images/svg/icon_prev_line.svg', gridBox.x + numberColumn * widthColumn - 30, 10, 28, 16)
                .attr({
                  zIndex: 100,
                  cursor: 'pointer',
                  'data-report': 'action',
                })
                .translate(0, 0)
                .addClass('image-plot-line image-plot-line-prev')
                .on('mousedown', this.start)
                .add()
              this.image2.on('mousedown', this.start)
            }
            this.line = chart.xAxis[0].plotLinesAndBands[0].svgElem.translate(0, 0)
          })
        } else {
          this.clearTooltip()
        }
      }
    },
    redraw(e) {
      let chart = e.target
      this.chart = chart
      if (chart.oldChartWidth && chart.chartWidth != this.oldChartWidth) {
        this.oldChartWidth = chart.chartWidth
      } else if (this.prevStep != this.step || (this.prevPart && this.prevPart != this.part)) {
        this.prevStep = this.step
        this.prevPart = this.part
        this.loaded(chart)
      }
    },
    start(e) {
      let chart = this.chart
      jQuery(document).bind({
        'mousemove.image-plot-line': this.steps,
        'mouseup.image-plot-line': this.stop,
      })
      let gridBox = chart.xAxis[0].gridGroup.getBBox()
      let widthColumn = gridBox.width / this.column
      let numberColumn = (this.currentSelected - chart.xAxis[0].min) / this.step
      if (jQuery(e.target).hasClass('image-plot-line-next')) {
        if (this.image) {
          this.image.destroy()
          this.image = null
        }
        this.image = chart.renderer
          .image('/images/svg/icon_next_line_hover.svg', gridBox.x + numberColumn * widthColumn + 2, 10, 28, 16)
          .attr({
            zIndex: 100,
            cursor: 'pointer',
            'data-report': 'action',
          })
          .translate(0, 0)
          .addClass('image-plot-line image-plot-line-next')
          .on('mousedown', this.start)
          .add()
      } else {
        if (this.image2) {
          this.image2.destroy()
          this.image2 = null
        }
        this.image2 = chart.renderer
          .image('/images/svg/icon_prev_line_hover.svg', gridBox.x + numberColumn * widthColumn - 30, 10, 28, 16)
          .attr({
            zIndex: 100,
            cursor: 'pointer',
            'data-report': 'action',
          })
          .translate(0, 0)
          .addClass('image-plot-line image-plot-line-prev')
          .on('mousedown', this.start)
          .add()
      }
      this.line = chart.xAxis[0].plotLinesAndBands[0].svgElem.translate(0, 0)
      this.clickX = e.pageX - this.line.translateX
    },
    steps(e) {
      let chart = this.chart
      let gridBox = chart.xAxis[0].gridGroup.getBBox()
      let widthColumn = gridBox.width / this.column
      let currentVideoMaxX = this.getXMax(chart) < chart.xAxis[0].max ? this.getXMax(chart) : chart.xAxis[0].max
      let maxWidthExtra = (widthColumn * (currentVideoMaxX - this.currentSelected)) / this.step
      let numberColumn = (this.currentSelected - (this.part - 1) * this.column * this.step) / this.step
      let maxWidthMinus = widthColumn * numberColumn
      let translateX = e.pageX - this.clickX
      if (maxWidthMinus + translateX < 0) return
      if (translateX > maxWidthExtra) return

      if (this.line) {
        this.line.translate(translateX)
      }
      if (this.image) {
        this.image.translate(translateX)
      }
      if (this.image2) {
        this.image2.translate(translateX)
      }
      this.tooltipEl.attr('x', this.positionTooltip + translateX)
    },
    stop() {
      let chart = this.chart
      let gridBox = chart.xAxis[0].gridGroup.getBBox()
      let widthColumn = gridBox.width / this.column
      this.prevSelected = this.currentSelected
      this.currentSelected =
        this.currentSelected + Math.round((this.line.translateX / chart.plotWidth) * this.column) * this.step
      if (this.currentSelected > this.getXMax(chart)) {
        this.currentSelected = this.currentSelected - this.step
      }
      let numberColumn = (this.currentSelected - (this.part - 1) * this.column * this.step) / this.step
      jQuery(document).unbind('.image-plot-line')

      let numberMarker = 1
      if (this.isShared) {
        numberMarker = this.numberElmTooltip
      }

      this.clearTooltip()
      let positionX = (numberColumn * chart.plotWidth) / this.maxColumn + 5
      let yMax = chart.yAxis[0].max

      let positionYMax = Number.MAX_VALUE
      for (let i = 1; i <= numberMarker; i++) {
        chart.get(this.arrIdSelected[i - 1]).data.map(e => {
          if (e.x != this.currentSelected) {
            e.marker = {}
          } else {
            e.marker = { enabled: true }
          }
          return e
        })
        let currentY = chart.get(this.arrIdSelected[i - 1]).yData[this.currentSelected / this.step]
        let positionY = chart.plotHeight - (currentY / yMax) * chart.plotHeight - 69
        if (positionY < 60) {
          positionY = chart.plotHeight - (currentY / yMax) * chart.plotHeight + 30
        } else if (positionY > 237) {
          positionY = chart.plotHeight - (currentY / yMax) * chart.plotHeight - 62
        }

        // if currentY and yMax = 100, increase positionY
        if (currentY >= 100) {
          positionY = currentY
        }

        if (currentY == 100 && yMax == 100) {
          positionY = positionY + 20
        }

        if (positionY < positionYMax) positionYMax = positionY
      }

      this.drawTooltipSVG(chart, positionX, positionYMax)

      chart.xAxis[0].options.plotLines[0].value = this.currentSelected
      chart.xAxis[0].update()
      this.line = chart.xAxis[0].plotLinesAndBands[0].svgElem.translate(0, 0)
      if (numberColumn != 0) {
        this.image2 = chart.renderer
          .image('/images/svg/icon_prev_line.svg', gridBox.x + numberColumn * widthColumn - 30, 10, 28, 16)
          .attr({
            zIndex: 100,
            cursor: 'pointer',
            'data-report': 'action',
          })
          .translate(0, 0)
          .addClass('image-plot-line image-plot-line-prev')
          .on('mousedown', this.start)
          .add()
      } else {
        this.image2 = null
      }

      let currentVideoMaxX =
        this.getXMax(chart) < chart.xAxis[0].max ? this.getXMax(chart) : chart.xAxis[0].max - this.step / 2
      if (numberColumn >= Math.floor((currentVideoMaxX - (this.part - 1) * this.column * this.step) / this.step)) {
        this.image = null
      } else {
        this.image = chart.renderer
          .image('/images/svg/icon_next_line.svg', gridBox.x + numberColumn * widthColumn + 2, 10, 28, 16)
          .attr({
            zIndex: 100,
            cursor: 'pointer',
            'data-report': 'action',
          })
          .translate(0, 0)
          .addClass('image-plot-line image-plot-line-next')
          .on('mousedown', this.start)
          .add()
      }
    },
    drawTooltipSVG(chart, positionX, positonY) {
      let data = {}
      let dataSecond = []
      let dataValue
      if (!this.questionIdSelected) {
        let attribute_value_id = this.selectedId.split('-')[1]
        //1429 check if id == all => use dataReportSummary
        if (attribute_value_id == 'all') {
          if (this.dataReportSummary?.tally_result && this.dataReportSummary?.tally_result[this.currentSelected]) {
            dataValue = this.dataReportSummary?.tally_result?.find(e => e.second == this.currentSelected)
          }

          let dataFormat = {
            left_count: dataValue?.left,
            right_count: dataValue?.right,
          }

          dataValue = { ...dataFormat }
        } else {
          if (this.dataReport && this.dataReport.data && this.dataReport.data[this.currentSelected]) {
            dataSecond = this.dataReport.data[this.currentSelected].values
          }
          dataValue = dataSecond.find(e => e.attribute_value_id == attribute_value_id)
        }
      } else {
        if (this.selectedAnswerId == 'all') {
          if (this.dataReportSummary?.tally_result && this.dataReportSummary?.tally_result[this.currentSelected]) {
            dataValue = this.dataReportSummary?.tally_result?.find(e => e.second == this.currentSelected)
          }

          let dataFormat = {
            left_count: dataValue?.left,
            right_count: dataValue?.right,
          }

          dataValue = { ...dataFormat }
        } else {
          if (this.reportScreening && this.reportScreening.data && this.reportScreening.data[this.currentSelected]) {
            dataSecond = this.reportScreening.data[this.currentSelected].values
          }
          dataValue = dataSecond.find(e => e.screening_answer_id == this.selectedAnswerId)
        }
      }

      if (dataValue) {
        data = { left: dataValue['left_count'], right: dataValue['right_count'] }
      } else {
        data = { left: 0, right: 0 }
      }
      let imgEl = getImageTooltipEl(this.imageURL + this.currentSelected + '.jpg')

      let reactionTagsEl = getReactionTagTooltipEl(this.project, {
        left: Math.abs(data.left) + '%',
        right: Math.abs(data.right) + '%',
        second: data.second,
      })
      let tooltipEl = getTooltipLineChart(imgEl, reactionTagsEl)
      this.tooltipEl = chart.renderer
        .text(tooltipEl, positionX, positonY, true)
        .attr({
          zIndex: 100,
          cursor: 'pointer',
          'data-report': 'action',
        })
        .add()
      this.positionTooltip = positionX
    },
    getMaxY(series) {
      let maxY = 0
      series.forEach(serie => {
        let max = Math.max(...serie.data.map(e => e.y))
        if (max > maxY) maxY = max
      })
      return maxY
    },
  },
  mounted() {
    this.chartOptions.xAxis.min = (this.part - 1) * this.step * this.column
    this.chartOptions.xAxis.tickInterval = this.step
    this.chartOptions.xAxis.max = (this.part - 1) * this.step * this.column + this.step * this.maxColumn
    this.currentSelected = (this.part - 1) * this.step * this.column
    this.chartOptions.xAxis.plotLines = [
      {
        className: 'line-show-tooltip',
        color: '#49AB94',
        width: 1,
        value: this.currentSelected,
        zIndex: 2,
      },
    ]
    this.chart = this.$refs.lineChartSummary.chart
    this.$emit('setChart', this.chart)
    this.initSeriesData()
    this.chart.redraw()
  },
  created() {
    this.initSeriesData()
  },
}
</script>
<style lang=""></style>
