<template> 
    <div>
        <div class="capture-videos has-video-selected mt-5" v-if="listImage != null && listImage.length">
            <div class="list-mark" >
            </div>
            <div class="type-reaction">
                <div :class="'type-all-reaction'" class="type mr-2">Reaction</div>
                <div :class="'type-'+interactionColorClass.rank_2" class="type mr-2">{{interactionText.rank_2}}</div>
                <div :class="'type-'+interactionColorClass.rank_3" class="type">{{interactionText.rank_3}}</div>
            </div>
            <div class="list-video-captures">
                <div class="video-capture">
                    <div class="images mt-2" id="video-image" v-if="listImage != null && listImage.length" ref="imageVideo">
                        <div class="image-second" v-for="(item,index) in listImage" :key="item.second">
                            <div v-if="Object.keys(item).length">
                                <div class="second-plus" ref="secondLine" :data-second="item.second" :style="{width: getWidth(item.second, index)}">
                                    <div class="second-add-label" v-for="(second,idx) in noLineSeconds" :key="`second-${idx}`"  data-scene-report="action">
                                        <div class="second-plus--add-mark" v-if="second == item.second" @click="addTabularLine(second)" data-scene-report="action">
                                            <i class="icon icon-second-add" data-scene-report="action"></i>
                                        </div>
                                    </div>
                                    <div v-for="(tabular,index) in listTabular" :key="tabular.tabular_line_id" :style="{width : '100%',position:'absolute'}">
                                        <div class="second-plus--info-mark" v-if="tabular.tabular.second.start == item.second || tabular.tabular.second.start <= 13 * (page-1) && tabular.tabular.second.end >= 13 * (page-1) && item.second == 13 * (page-1)">
                                            <div class="line-text">
                                                <div class="edit-line-second">
                                                    <div class="line-second-inner">
                                                        <div class="edit-second edit-first-second">
                                                            <input class="input-first-line" type="number" data-scene-report="action" :active="tabular.tabular.second.start" :value="tabular.tabular.second.start" @change="editFirstLine(index, tabular)" :class="[tabular.tabular.second.start == 13*(page-1) ? 'input-short' : '']">
                                                            <p :class="tabular.tabular.second.start == 13*(page-1) ? 'second-short' : ''">s</p>
                                                        </div>
                                                        <div class="between">
                                                            <p>~</p>
                                                        </div>
                                                        <div class="edit-second edit-second-second">
                                                            <input class="input-second-line" type="number" data-scene-report="action" :active="tabular.tabular.second.end" :value="tabular.tabular.second.end" @change="editSecondLine(index, tabular)" :class="[tabular.tabular.second.end == 13*(page-1) ? 'input-short' : '', tabular.tabular.second.end < 10 ? 'one-digit' : tabular.tabular.second.end > 10 && tabular.tabular.second.end < 100 ? 'two-digit' : '']">
                                                            <p :class="[tabular.tabular.second.end == 13*(page-1) ? 'second-short' : '',tabular.tabular.second.end < 10 ? 'one-digit-second' : tabular.tabular.second.end > 10 && tabular.tabular.second.end < 100 ? 'two-digit-second' : '']">s</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <span>{{tabular.tabular.cell_notes.content}}</span>
                                                <div class="trash"><i class="icon icon-trash" data-scene-report="action" @click="deleteTabularLine(tabular)"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tag-seconds">{{item.second}}s</div>
                                <div class="capture" @click="choseTimeVideo(item.second)"><img class="capture-second" :src="item.image_url" :class="(selectedVideoCaptureSecond == item.second)? 'selected-img' : ''"><i class="icon icon-arrow" :class="(index+start == duration)? 'hide': ''"></i></div>
                                <div class="tag-number" v-if="Object.keys(item.rank_good).length && interactionColorClass.rank_2.length" :class="'tag-'+interactionColorClass.rank_2">
                                    <div class="ranking"><span>{{item.rank_good.rank}}</span></div>
                                    <div class="">{{item.rank_good.interaction_count}}%</div>
                                </div>
                                <div class="tag-number" v-if="Object.keys(item.rank_bad).length && interactionColorClass.rank_3.length" :class="'tag-'+interactionColorClass.rank_3">
                                    <div class="ranking"><span>{{item.rank_bad.rank}}</span></div>
                                    <div class="">{{item.rank_bad.interaction_count}}%</div>
                                </div>
                                <div class="tag-number tag-total-reaction" v-if="Object.keys(item.rank_total_reaction).length && interactionColorClass.rank_3.length">
                                    <div class="ranking"><span>{{item.rank_total_reaction.rank}}</span></div>
                                    <div class="">{{item.rank_total_reaction.interaction_count}}%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn-next-prev" v-if="listImage != null && listImage.length">
                <i class="icon icon-prev" @click="changeListImagePrev(false)" v-if="isIconPrev" ref="prevButton"></i>
                <i class="icon icon-next" @click="changeListImageNext(false)" v-if="isIconNext" ref="nextButton"></i>
            </div>
        </div>
        <div class="labels-list mt-8" v-if="videoImage && videoImage.duration > 0"> 
            <div class="table-labels table-wrapper">
                <div class="loader" v-if="isLoading"></div>
                <table id="tabledata" class="table table-scroll basic">
                    <colgroup>
                        <col class="table-col"/>
                        <col class="table-col"/>
                        <col class="table-col"/>
                        <col class="table-col"/>
                        <col class="table-col"/>
                        <col class="table-col"/>
                    </colgroup>
                    <tr>
                        <th style="width : 20%"><span>シーン</span></th>
                        <!-- <th><span>Second</span></th> -->
                        <th style="width : 10%" v-if="interactionText.rank_2.length"><span>{{ interactionText.rank_2 }}</span></th>
                        <th style="width : 10%" v-if="interactionText.rank_3.length"><span>{{ interactionText.rank_3 }}</span></th>
                        <th style="width : 10%"><span>総リアクション</span></th>
                        <th style="width : 50%"><span>自由記入欄<ToolTip :text="$t('texts.tooltip.comment')"></ToolTip></span></th>
                    </tr>
                    <tr v-for="item,index in listTabular" :key="'line-'+item.tabular_line_id">
                        <td  style="width : 20%"
                             :data-index="index" data-row="cell_notes"
                             :data-cell="0"
                             :data-id="item.tabular_line_id"
                             :class="item.tabular.cell_notes.updated_user_name ? 'col-edit-text' : ''"
                             class="col-edit-cell-notes col-note"
                        >
                            <span class="tooltiptext" v-if="item.tabular.cell_notes.updated_user_name">最終編集者：{{item.tabular.cell_notes.updated_user_name}}</span>
                            <textarea @input="setHeight($event)"
                                      class="table-textarea input-content input-cell-note mr-5"
                                      v-model="item.tabular.cell_notes.content"
                                      @change="editCellNote(index)"
                                      data-comment-scene="action"
                            ></textarea>
                                <img
                                class="img-cell-note"
                                :src="getImageSecond(item.tabular.second.start)"
                                alt=""
                                />
                        </td>
                        <!-- <td>
                            <div :data-index="index" :data-start_second="item.tabular.second.start" :data-end_second="item.tabular.second.end" class="col-edit-line background"
                            >
                            <span class="second-start">{{item.tabular.second.start + '~'}}</span>
                            <input class="input-second-line" type="number" v-model="item.tabular.second.end" @change="editSecondLine(index)">
                            </div>
                        </td> -->
                        <td v-if="interactionColor.rank_2.length" :style="[getMax(index,maxGoodInteraction) ? {color : interactionColor.rank_2, fontWeight : 'bold'} : {color : defaultColor}]">
                            <div class="background">
                                <span>{{average(item.tabular.lefts)}}</span>
                            </div>
                        </td>
                        <td v-if="interactionColor.rank_3.length" :style="[getMax(index,maxBadInteraction) ? {color : interactionColor.rank_3, fontWeight : 'bold'} : {color : defaultColor}]">
                            <div class="background">
                                <span>{{average(item.tabular.rights)}}</span>
                            </div>
                        </td>
                        <td v-if="interactionColor.rank_2.length && interactionColor.rank_3.length" :style="[getMax(index,maxAvgInteraction) ? {color : avgColor, fontWeight : 'bold'} : {color : defaultColor}]">
                            <div class="background">
                                <span>{{ totalAvg( average(item.tabular.lefts),average(item.tabular.rights))  }}</span>
                            </div>
                        </td>
                        <td v-if="interactionColor.rank_3.length && !interactionColor.rank_2.length" :style="[getMax(index,maxBadInteraction) ? {color : avgColor, fontWeight : 'bold'} : {color : defaultColor}]">
                            <div class="background">
                                <span>{{average(item.tabular.rights)}}</span>
                            </div>
                        </td>
                        <td v-if="interactionColor.rank_2.length && !interactionColor.rank_3.length" :style="[getMax(index,maxGoodInteraction) ? {color : avgColor, fontWeight : 'bold'} : {color : defaultColor}]">
                            <div class="background">
                                <span>{{average(item.tabular.lefts)}}</span>
                            </div>
                        </td>
                        <td style="width : 50%" :data-index="index" data-row="free_notes" :data-cell="1" :data-id="item.tabular_line_id" :class="item.tabular.free_notes.updated_user_name ? 'col-edit-text' : ''" class="col-edit-free-notes  col-note" >
                            <span class="tooltiptext" v-if="item.tabular.free_notes.updated_user_name">最終編集者：{{item.tabular.free_notes.updated_user_name}}</span>
                            <textarea
                                rows="1"
                                @input="setHeight($event)"
                                class="table-textarea input-content input-free-note"
                                type="text"
                                v-model="item.tabular.free_notes.content"
                                @change="editFreeNote(index)"
                                @keydown="onKeydownEditFreeNote(index)"
                                data-comment-scene="action"
                            ></textarea>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import {
  numberWithCommas
} from '@/commons/helpers';
import EventBus from "@/commons/event_bus";
import { cellFlg, colorInteraction } from "@/commons/constants";
import ToolTip from "@/components/Commons/ToolTip.vue";
const jQuery = require('jquery');
export default {
  name: "VideoCapture",
  components: { ToolTip },
  props: ['videoDetail', 'videoImage', 'reportVideo','tabulars'],
  data() {
    return {
        listImage: null,
        start: 0,
        end: 0,
        duration: 0,
        isIconNext: true,
        isIconPrev: false,
        listTabular : [],
        secondsLength : 0,
        startSecond : 0,
        endSecond : 0,
        cellFlg : 0,
        contents : "",
        status : 1,
        currentUser: this.$store.getters.currentUser,
        lineId : null,
        listCellFlg : cellFlg,
        videoId : null,
        avgColor : '#49AB94',
        defaultColor :'#333333',
        page : 1, 
        isLoading : false,
        isLineSeconds: [],
        noLineSeconds: [],
        caputureWidth: 0,
        selectedVideoCaptureSecond: null
    };
  },
  watch: {
    videoImage: function() {
        this.setStartEndImage(this.start);
        this.getNoLineId(this.tabulars)
        this.getCaputureWidth(500)
    },
    tabulars: function(data) {
        this.getlistTabular(data)
        this.getNoLineId(data)
    },
    videoDetail: function(data){
        this.videoId = data.id;
    },
    listTabular: function() {
        this.$nextTick(()=>{
            let textArea = jQuery('.table-textarea')
            textArea.each(function() {
                if ((this.scrollHeight - jQuery(this).height()) > 4) {
                    let height = this.scrollHeight;
                    this.style.height = height + 'px';

                } 
            })
        })
    },
    page: function(){
        this.getCaputureWidth(0);
    }
  },
  created(){
    EventBus.$on('changeCurrentTime', this.handleCurrentTime);
  },
  beforeDestroy() {
    EventBus.$off('changeCurrentTime', this.handleCurrentTime);
  },
  mounted() {
    this.setStartEndImage(this.start);
    this.videoId = this.videoDetail.id
    this.getlistTabular(this.tabulars)
    setTimeout(() => {
        this.getNoLineId(this.listTabular);
    }, 300);
    window.addEventListener('resize', function(){
      this.getCaputureWidth(0)
    }.bind(this))
  },

  updated() {
    this.setHeightOfInputFreeNote()
    jQuery("#tabledata textarea").each(function () {
      jQuery(this).css("height", "29px");
    });
    jQuery("#tabledata textarea").each(function () {
      if (jQuery(this).prop("scrollHeight") - jQuery(this).height() > 4) {
        jQuery(this).css("height", "29px");
        let height = jQuery(this).prop("scrollHeight");
        if(jQuery(this).height() > 40) {
          jQuery(this).css("height", height - 2 + "px");
        } else {
          jQuery(this).css("height", height + "px");
        }
      }
    });
    this.getHeightMax();
  },

  methods: {
    getImageSecond(index = 0) {
      const urlArr = this.listImage[0]?.image_url.split("/");
      urlArr.pop();
      const url = urlArr.join("/");
      return url + `/${index}.jpg`;
    },
    onKeydownEditFreeNote(index) {
      let inputFreeNote = [...jQuery("#tabledata .input-free-note")][index]
      setTimeout(function () {
        inputFreeNote.style.cssText = 'height:29px; padding:0'
        inputFreeNote.style.cssText = 'height:' + inputFreeNote.scrollHeight + 'px'
      },0)
    },

    setHeightOfInputFreeNote() {
        let inputFreeNoteList = [...jQuery("#tabledata .input-free-note")];
        inputFreeNoteList.forEach((inputFreeNote) => {
          if ((inputFreeNote.scrollHeight - jQuery(inputFreeNote).height()) > 4) {
            let height = inputFreeNote.scrollHeight;
            inputFreeNote.style.height = height + 'px';
          }
        })
    },
    getHeightMax() {
      let children = jQuery("#tabledata tr");
      children.each(function () {
        let heightMax = 40;
        jQuery(this)
          .children()
          .each(function () {
            jQuery(this).css("min-height", "29px");
            if (heightMax < jQuery(this).height())
              heightMax = jQuery(this).height();
          });
        jQuery(this)
          .children()
          .each(function () {
            jQuery(this).css("min-height", heightMax + "px");
          });
      });
    },
    getMax(index,maxInteraction){
        let idx = maxInteraction.filter(item => item == index)
        if(idx.length > 0) return true;
        else return false
    },
    setHeight(e) {
      jQuery("#tabledata textarea").each(function () {
        jQuery(this).css("height", "29px");
      });
      this.getHeightMax();
      if (e.target.scrollHeight - jQuery(e.target).height() > 4) {
        e.target.style.height = "29px";
        let height = e.target.scrollHeight;
        e.target.style.height = height + "px";
      }
    },
    getWidth(second, index) {  
        let tabular = this.listTabular.find(e => e.tabular.second.start == second);
        if(second == 13 * (this.page-1)){
            for(let i = 0; i < this.listTabular.length; i++){
                if(this.listTabular[i].tabular.second.end >= 13 * (this.page-1) && this.listTabular[i].tabular.second.start <= 13 * (this.page-1)){
                    tabular = this.listTabular[i];
                }
            }
        }
        if (tabular) {
            if(tabular && tabular.tabular.second.start <= 13 * (this.page-1) && tabular.tabular.second.end >= 13 * (this.page-1) && second == 13 * (this.page-1)){
                let length = tabular.tabular.second.old_end + 1 - 13 * (this.page-1);
                length > 13 ? length = 13 : length;
                return length*100+'%'
            } else if(tabular && tabular.tabular.second.end >= (this.page * 13) -1){
                let length = this.page * 13 - tabular.tabular.second.start;
                return length*100+'%'
            }else{
                let length = tabular.tabular.second.old_end + 1 - tabular.tabular.second.start;
                length > 13 ? length = 13 - tabular.tabular.second.start : length;
                return length*100+'%'
            }
        } else {
            if (!index) {
                tabular = this.listTabular.find(e => e.tabular.second.start < second && e.tabular.second.old_end >= second);
                if (tabular) {
                    let length = tabular.tabular.second.old_end + 1 - second;
                    return length*100+'%'
                }
            }
            return '100%'
        }
    },
    getCaputureWidth(time){
        setTimeout(function () {
            const caputure = document.getElementsByClassName('capture-second')[0];
            if(caputure && caputure.offsetWidth){
                this.caputureWidth = document.getElementsByClassName('capture-second')[0].offsetWidth;
                let target = document.getElementsByClassName('second-plus--add-mark');
                for(let i = 0; i < target.length; i++){
                    target[i].style.width = `${this.caputureWidth}px`;
                }
            }
        },time)
    },
    checkXss(content){
        let re = /^[^<>&";]+$/;
        return re.test(content)
    },
    checkNaturalNumber(number){
        let re = /^(0|([1-9]\d*))$/;
        return re.test(number)
    },
    setStartEndImage(start) { 
       if (this.$props.videoImage) {
            let image_show = 0;
            if (this.page >= 1) image_show = 13;
            let duration = this.$props.videoImage.duration
            let url = this.$props.videoImage.image_url
            let interaction_rank = this.$props.videoImage.interaction_rank;
            let listRankTotalReaction = interaction_rank["total_interaction_rank"]
            let listRankGood = interaction_rank["2_rank"];
            let listRankBad = interaction_rank["3_rank"];
            this.duration = duration;
            this.start = start
            this.end = start + image_show - 1

            if (duration <= image_show -1 || this.end >= duration) {
                this.isIconNext = false
            } else {
                this.isIconNext = true
            }

            if (this.page > 1) {
                this.isIconPrev = true
            } else {
                this.isIconPrev = false
            }
            this.listImage = [];
            for (let i = this.start; i <= this.end; i++) {
                let second = {}
                let image_url =  url + i + '.jpg';
                let rank_total_reaction = {};
                let rank_good = {};
                let rank_bad = {};
                for (let item in listRankGood) {
                    if (listRankGood[item].second == i) {
                        listRankGood[item].interaction_count = numberWithCommas(listRankGood[item].interaction_count)
                        rank_good = listRankGood[item]
                    }
                    listRankGood[item].interaction_count = Number.isInteger(listRankGood[item].interaction_count) ? listRankGood[item].interaction_count + '.0' : listRankGood[item].interaction_count
                }
                for (let item in listRankBad) {
                    if (listRankBad[item].second == i) {
                        listRankBad[item].interaction_count = numberWithCommas(listRankBad[item].interaction_count)
                        rank_bad = listRankBad[item]
                    }
                    listRankBad[item].interaction_count = Number.isInteger(listRankBad[item].interaction_count) ? listRankBad[item].interaction_count + '.0' : listRankBad[item].interaction_count
                }
                for (let item in listRankTotalReaction) {
                    if (listRankTotalReaction[item].second == i) {
                        listRankTotalReaction[item].interaction_count = numberWithCommas(listRankTotalReaction[item].interaction_count)
                        rank_total_reaction = listRankTotalReaction[item]
                    }
                    listRankTotalReaction[item].interaction_count = Number.isInteger(listRankTotalReaction[item].interaction_count) ? listRankTotalReaction[item].interaction_count + '.0' : listRankTotalReaction[item].interaction_count
                }
                second['image_url'] = image_url
                second['rank_total_reaction'] = rank_total_reaction
                second['rank_good'] = rank_good
                second['rank_bad'] = rank_bad
                second['second'] = i
                if (i <= duration) {
                    this.listImage.push(second)  
                } else {
                    this.listImage.push({})  
                }
            }
        }
    },
    changeListImageNext(linkChartFlg) {
        let ref = this.$refs.imageVideo;
        this.$nextTick(()=>{
            ref.style.width = '100%';
        })
        if (this.end < this.duration) {
            if(!linkChartFlg) this.page += 1;
            this.start = (this.page-1)*13
            this.setStartEndImage(this.start);
            let lastLine;
            if(this.listTabular.length > 0) {
                lastLine = this.listTabular[this.listTabular.length - 1];
                this.secondsLength = lastLine.tabular.second.end + 1;
            }
            else  this.secondLength = 0;
            this.getTabulars();
        
        }
    },
    changeListImagePrev(linkChartFlg) {
        if(!linkChartFlg) this.page -= 1;
        this.start = (this.page-1)*13;
        let lastLine;
        this.setStartEndImage(this.start);
        if(this.listTabular.length > 0) {
            lastLine = this.listTabular[this.listTabular.length - 1];
            this.secondsLength = lastLine.tabular.second.end + 1;
        }
        else  this.secondLength = 0;
        this.getTabulars();
    },
    choseTimeVideo(second) {
        EventBus.$emit('changeCurrentTime', second);
        this.selectedVideoCaptureSecond = second
    },
    handleCurrentTime(currentTime) {
        this.selectedVideoCaptureSecond = this.selectedVideoCaptureSecond != currentTime ? currentTime : this.selectedVideoCaptureSecond
        let linkChartFlg = false
        if(this.selectedVideoCaptureSecond < (13*(this.page-1)) ){
            this.page = Math.floor((this.selectedVideoCaptureSecond / 13) + 1);
            linkChartFlg = true
            this.changeListImagePrev(linkChartFlg)
        } else if(this.selectedVideoCaptureSecond > (13*(this.page)-1)){
            this.page = Math.floor((this.selectedVideoCaptureSecond / 13) + 1);
            linkChartFlg = true
            this.changeListImageNext(linkChartFlg)
        }
    },
    editFreeNote(index){
        let vm = this;
        let divList = [...jQuery("#tabledata .input-free-note")];
        divList.forEach((div,idx)=>{
            if(idx == index) {
                let val = jQuery(div).val().trim();
                const lineId = jQuery(div).parent().data('id');
                const cellFlg = jQuery(div).parent().data('cell');
                vm.lineId = lineId;
                vm.cellFlg = cellFlg;
                vm.contents = val;
                if(vm.checkXss(val) && val.length > 0){
                    vm.isLoading = true;
                    vm.updateVideoTabularContent()
                } 
                if(!vm.checkXss(val) && val.length > 0) {
                    vm.$toasted.error("自由記入欄に「<>&\";」の文字は使用できません");
                    vm.getTabulars()
                    return;
                }
                if (val.length == 0) {
                    vm.isLoading = true;
                        vm.updateVideoTabularContent()
                }
                jQuery(div).trigger('blur');

                
            }

        })
        
    },
    editCellNote(index){
        let vm = this;
        let divList = [...jQuery("#tabledata .input-cell-note")];
        divList.forEach((div,idx)=>{
            if(idx == index) {
                let val = jQuery(div).val().trim();
                const lineId = jQuery(div).parent().data('id');
                const cellFlg = jQuery(div).parent().data('cell');
                vm.lineId = lineId;
                vm.cellFlg = cellFlg;
                vm.contents = val;
                if(vm.checkXss(val) && val.length > 0){
                    vm.isLoading = true;
                    vm.updateVideoTabularContent()
                } 
                if(!vm.checkXss(val) && val.length > 0) {
                    vm.$toasted.error("シーンに「<>&\";」の文字は使用できません");
                    jQuery(div).trigger('blur');
                    vm.getTabulars()
                    return;
                }
                if (val.length == 0) {
                    vm.isLoading = true;
                        vm.updateVideoTabularContent();
                }
                jQuery(div).trigger('blur');

            }

        })
       
    },
    editFirstLine(index, tabular){
        let pageBeforeLineCount = 0;
        if(this.page > 1){
            for(let i = 0; i < this.listTabular.length; i++){
                if(this.listTabular[i].tabular.second.end > 13*(this.page-1) && this.listTabular[i].tabular.second.end < tabular.tabular.second.start && this.listTabular[i].tabular_line_id !== tabular.tabular_line_id){
                    pageBeforeLineCount++;
                }
            }
        }
        let vm = this;
        let divList = [...jQuery(".input-first-line")];
        divList.forEach((div,idx)=>{
            if(this.page <= 1 && idx == index || this.page > 1 && idx == pageBeforeLineCount) {
                let val = jQuery(div).val();
                let endSecond = vm.listTabular[index].tabular.second.end;
                let lineId = vm.listTabular[index].tabular_line_id;
                vm.lineId = lineId;
                vm.endSecond = endSecond;
                
                if (Number(val) > vm.$props.videoImage.duration || !val) {
                    vm.$toasted.error('指定された秒数の行は登録できません');
                    vm.getTabulars();
                    jQuery(div).trigger('blur');
                    return;
                } else {
                    if(vm.checkNaturalNumber(Number(val)) && Number(val) >= 0) {
                        vm.startSecond = Number(val);
                        vm.isLoading = true;
                        vm.updateVideoTabularLine();
                    } else {
                        vm.$toasted.error("開始秒数または終了秒数の設定が誤っています");
                        vm.getTabulars();
                        jQuery(div).trigger('blur');
                        return;
                    }
                }
               
                jQuery(div).trigger('blur')
            }
        })
    },
    editSecondLine(index, tabular){
        let pageBeforeLineCount = 0;
        if(this.page > 1){
            for(let i = 0; i < this.listTabular.length; i++){
                if(this.listTabular[i].tabular.second.end >= 13*(this.page-1) && this.listTabular[i].tabular.second.end <= tabular.tabular.second.start && this.listTabular[i].tabular_line_id !== tabular.tabular_line_id){
                    pageBeforeLineCount++;
                }
            }
        }
        let vm = this;
        let divList = [...jQuery(".input-second-line")];
        divList.forEach((div,idx)=>{
            if(idx == index || this.page > 1 && idx == pageBeforeLineCount) {
                let val = jQuery(div).val();
                let startSecond = vm.listTabular[index].tabular.second.start;
                let lineId = vm.listTabular[index].tabular_line_id;
                vm.lineId = lineId;
                vm.startSecond = startSecond;
                
                if (Number(val) > vm.$props.videoImage.duration || !val) {
                    vm.$toasted.error('指定された秒数の行は登録できません');
                    vm.getTabulars();
                    jQuery(div).trigger('blur');
                    return;
                } else {
                    if(vm.checkNaturalNumber(Number(val)) && Number(val) >= 0) {
                        vm.endSecond = Number(val);
                        vm.isLoading = true;
                        vm.updateVideoTabularLine();
                    } else {
                        vm.$toasted.error("開始秒数または終了秒数の設定が誤っています");
                        vm.getTabulars();
                        jQuery(div).trigger('blur');
                        return;
                    }
                }
               
                jQuery(div).trigger('blur')
            }
        })
        
    },
    average(array) {
        if(Array.isArray(array)){
            let arrayLength = array.length;
            let reducer = (prevValue, currentValue) => prevValue + currentValue;
            let number = Number(array.reduce(reducer)/ arrayLength).toFixed(2);
            let result = number.substring(0, number.length - 1);
           return result
        }
        else return 0;
    },
    totalAvg(num1,num2){
        let total = (Number(num1) + Number(num2)).toFixed(2);
        let result = total.substring(0,total.length - 1)
        return result
    },
    getListInteractionPerSecond(start, end, type){
      let result = []; 
      for(let i = start; i <= end; i++) {
          if(Object.keys(this.reportVideo).length) {
              if(type == "left" && this.reportVideo.tally_result[i]) result.push(Math.abs(this.reportVideo.tally_result[i].left))
              if(type == "left" && !this.reportVideo.tally_result[i]) result.push(0)

              if(type == "right" && this.reportVideo.tally_result[i]) result.push(Math.abs(this.reportVideo.tally_result[i].right))
              if(type == "right" && !this.reportVideo.tally_result[i])  result.push(0)
              
          }
          else {
              if(type == "left") result.push(0)
              if(type == "right") result.push(0)
          }
      }
      return result;
    },
    addTabularLine(second){
        this.saveVideoTabularLine(second)
    },
    deleteTabularLine(tabular){
        this.lineId = tabular.tabular_line_id;
        this.startSecond = tabular.tabular.second.start;
        this.endSecond = tabular.tabular.second.end;
        this.status = 1;
        this.deleteVideoTabularLine();
        this.getCaputureWidth(300);
    },
    // api
    saveVideoTabularLine(index) {
        this.startSecond = index;
        this.endSecond = index;
        let url = this.$router.resolve({
        name: "api.video-tabular-line.register",
        }).href;
        let params = {
           video_id : this.videoImage.video_id,
           start_second : this.startSecond,
           end_second : this.endSecond
        };
        this.$http2.post(
            url,
            params,
            () => {
                this.getTabulars()
            },
            () => {
            }
        );
    },
    updateVideoTabularLine(){
        let query = {
            id: this.lineId,
        };
        let url = this.$router.resolve({
            name: "api.video-tabular-line.update",
            params: query,
        }).href;
        let params = {
           start_second : this.startSecond,
           end_second : this.endSecond,
           status : this.status,
           delete_flg : 0
        }
        this.$http2.put(
            url,
            params,
            () => {
                this.getTabulars();
                this.isLoading = false;
            },
            () => {
                this.getTabulars();
                this.isLoading = false;
            }
        );
    },
    deleteVideoTabularLine(){
        let query = {
            id: this.lineId,
        };
        let url = this.$router.resolve({
            name: "api.video-tabular-line.update",
            params: query,
        }).href;
        let params = {
           start_second : this.startSecond,
           end_second : this.endSecond,
           status : this.status,
           delete_flg : 1
        }
        this.$http2.put(
            url,
            params,
            () => {
                this.getTabulars();
                this.isLoading = false;
            },
            () => {
                this.getTabulars();
                this.isLoading = false;
            }
        );
    },
    updateVideoTabularContent(){
        let query = {
            id: this.lineId,
        };
        let url = this.$router.resolve({
            name: "api.video-tabular-contents.update",
            params: query,
        }).href;
        let params = {
           cell_flg : this.cellFlg,
           contents : this.contents,
           user_id : this.currentUser.user_id
        };
        this.$http2.put(
            url,
            params,
            () => {
                this.isLoading = false;
                this.getTabulars()
            },
            () => {
            }
        );
    },
    getTabulars(){
      let params = {
        video_id : this.videoId
      }
      let url = this.$router.resolve({
          name: "api.video-tabular.detail",
          
      }).href;
      this.$http2.get(url , params, (data)=>{
        this.listTabular = data.map(item=>{
            return {
                tabular_line_id : item.tabular_line_id,
                tabular : {
                    cell_notes : item.tabular.cell_notes,
                    free_notes: item.tabular.free_notes,
                    second : {
                        start : item.tabular.second.start,
                        end : item.tabular.second.end,
                        old_end: item.tabular.second.end
                    },
                    lefts : this.getListInteractionPerSecond(item.tabular.second.start, item.tabular.second.end, "left"),
                    rights : this.getListInteractionPerSecond(item.tabular.second.start, item.tabular.second.end, "right")
                }
            }
        });
        let lastLine = this.listTabular[this.listTabular.length - 1];
        this.secondsLength = lastLine.tabular.second.end + 1;
        this.getNoLineId(data);
      },()=>{
        this.getNoLineId(this.listTabular);
      });
    },
    getlistTabular(data) {
        this.listTabular = data;
        if(data.length) {
            let lastLine = data[data.length - 1].tabular.second.end;
            this.secondsLength = lastLine + 1;
        }
    },
    getNoLineId(data){
        this.isLineSeconds = [];
        this.noLineSeconds = [];
        if(data.length) {
            let start = 0;
            let end = 0;
            for(let i = 0; i < data.length; i++){
                start = data[i].tabular.second.start;
                end = data[i].tabular.second.end;
                for(let j = start; j <= end; j++){
                    this.isLineSeconds.push(j);
                }
            }
        } else {
            for(let i = 0; i <= this.duration; i++) {
                this.noLineSeconds.push(i);
            }
        }
        if(this.isLineSeconds.length){
            let lineSecondsArray = [];
            for(let i = 0; i <= this.duration; i++){
                lineSecondsArray.push(i);
            }
            this.noLineSeconds = lineSecondsArray.filter(i => this.isLineSeconds.indexOf(i) == -1)
        }
        this.getCaputureWidth(0)
    }
  },
  computed: {
    maxGoodInteraction(){
        let arr = this.listTabular.map(item => Number(this.average(item.tabular.lefts)));
        let max = Math.max(...arr)
        let index = arr.indexOf(max);
        let indices = [];
        while(index != -1) {
            indices.push(index);
            index = arr.indexOf(max, index + 1);
        }
        return indices;
    },
    maxBadInteraction(){
        let arr = this.listTabular.map(item => Number(this.average(item.tabular.rights)));
        let max = Math.max(...arr)
        let index = arr.indexOf(max);
        let indices = [];
        while(index != -1) {
            indices.push(index);
            index = arr.indexOf(max, index + 1);
        }
        return indices;
    },
    maxAvgInteraction(){
        let arr = this.listTabular.map(item => Number(this.average(item.tabular.rights)) + Number(this.average(item.tabular.lefts)));
        let max = Math.max(...arr)
        let index = arr.indexOf(max);
        let indices = [];
        while(index != -1) {
            indices.push(index);
            index = arr.indexOf(max, index + 1);
        }
        return indices;
    },
    interactionColorClass(){
        let color_rank_2 = this.videoDetail.interaction_btn_1 && Object.keys(this.videoDetail.interaction_btn_1).length ? this.videoDetail.interaction_btn_1.color : '';
        let color_rank_3 = this.videoDetail.interaction_btn_2 && Object.keys(this.videoDetail.interaction_btn_2).length ? this.videoDetail.interaction_btn_2.color : '';
        return {
            rank_2 : color_rank_2 == 1 ? 'good' : color_rank_2 == 2 ? 'nattoku' : color_rank_2 == 3 ? 'bad' : color_rank_2 == 4 ? 'suki' : '',
            rank_3 : color_rank_3 == 1 ? 'good' : color_rank_3 == 2 ? 'nattoku' : color_rank_3 == 3 ? 'bad' : color_rank_3 == 4 ? 'suki' : '',
        }
    },
    interactionText(){
        return {
            rank_2 : this.videoDetail.interaction_btn_1 && Object.keys(this.videoDetail.interaction_btn_1).length ? this.videoDetail.interaction_btn_1.text : '',
            rank_3 : this.videoDetail.interaction_btn_2 && Object.keys(this.videoDetail.interaction_btn_2).length ? this.videoDetail.interaction_btn_2.text : '',
        }
    },
    interactionColor() {
      let color_rank_2 = this.videoDetail.interaction_btn_1 && Object.keys(this.videoDetail.interaction_btn_1).length ? this.videoDetail.interaction_btn_1.color : '';
      let color_rank_3 = this.videoDetail.interaction_btn_2 && Object.keys(this.videoDetail.interaction_btn_2).length ? this.videoDetail.interaction_btn_2.color : '';
      return {
        rank_2 : color_rank_2 ? colorInteraction[color_rank_2].color : '',
        rank_3 : color_rank_3 ? colorInteraction[color_rank_3].color : ''
      }
    }
  }
  
};
</script>
<style lang="scss" scoped>
@import "./../../styles/_top_detail.scss";
.loader {
  border: 2px solid #49ab94;
  border-radius: 50%;
  border-top: 2px solid #ffffff;
  width: 32px;
  height: 32px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

</style>